import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import { Divider } from '@mui/material';
import { useMyNotiList, useMyNotiOptionChange } from '@/hooks/apiHooks';
import { Notification } from '@/types/api';
import {
  StyledLayout,
  Container,
} from '@/components/Profile/SecondItem/AlarmSettings/Accordion/styles';
import Toggle from '@/components/Profile/SecondItem/AlarmSettings/Accordion/Toggle';

const Accordion = ({ templateKey, activated, serviceName }: Notification) => {
  const [expanded, setExpanded] = useState(false);
  const { data, mutate } = useMyNotiList();
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, expanded: boolean) => {
    setExpanded(expanded);
  };

  const handleOnOff = (e: any) => {
    const value = !!e.target?.checked;
    useMyNotiOptionChange({ templateKey, activated: value }).then((_) => {
      mutate(
        data?.filter((res) =>
          res.templateKey === templateKey
            ? { templateKey, activated: value }
            : res,
        ),
      );
    });
  };

  return (
    <StyledLayout>
      <MuiAccordion
        expanded={expanded}
        onChange={handleChange}
        sx={{
          backgroundColor: theme.colors.light,
          borderRadius: '0.42rem',
          border: 'none',
          boxShadow: 'none',
        }}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography style={{ fontWeight: '600' }}>{serviceName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />
          <Container>
            <div>업데이트 알림</div>
            <FormControlLabel
              control={
                <Toggle defaultChecked={activated} onChange={handleOnOff} />
              }
              label={''}
            />
          </Container>
        </AccordionDetails>
      </MuiAccordion>
    </StyledLayout>
  );
};

export default Accordion;
