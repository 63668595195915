import React, { useState } from 'react';
import Image, { ImageProps } from 'next/image';

type P = {
  errorImage?: string;
};

const ImageWithErrorHandling = ({
  src,
  errorImage,
  ...props
}: ImageProps & P) => {
  const [imageError, setImageError] = useState(false);

  return (
    <Image
      src={
        imageError
          ? errorImage
            ? errorImage
            : '/images/image-error-gray.svg'
          : src
      }
      {...props}
      onError={() => {
        setImageError(true);
      }}
      onLoadingComplete={() => {
        setImageError(false);
      }}
    />
  );
};

export default ImageWithErrorHandling;
