// export const APPROVAL_UNREAD_URL = '/api/v1/approval/unreadReferee/filter';
//
// export const APPROVAL_MY_DOCUMENT = '/api/v1/approval/filter';

export const APPROVAL_AUTHOR_ME_URL = '/api/v1/approval/author/me';

export const APPROVAL_CONFIRM_ME_URL = '/api/v1/approval/confirm/me';

export const APPROVAL_UNREADREFEEREE_ME_URL =
  '/api/v1/approval/unreadReferee/me';

export const SA_HOLIDAY = '/api/v1/labs/sa/holiday/count';
export const SA_NO_PROGRESS_TAX = '/api/v1/labs/sa/tax/unsettled/count';
export const SA_NO_PROGRESS_CARD = '/api/v1/labs/sa/card/unsettled/count';

export const SA_NO_APPROVAL = '/api/v1/labs/sa/pending/count';

export const SA_APPROVAL_REJECT_TAX = '/api/v1/labs/sa/tax/rejected/count';
export const SA_APPROVAL_REJECT_CARD = '/api/v1/labs/sa/card/rejected/count';

export const FUTUBE_INFO = '/api/v1/labs/fuTube/info';
export const E_LAW_INFO = '/api/v1/labs/eLaw/info';

export const FINSTA_FEEDBACK = '/api/v1/labs/finstagram/feedback';

export const FINSTA_OKR = '/api/v1/labs/finstagram/okr';
