import styled from '@emotion/styled';

const StyledInput = styled.input`
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #e7e9ec;
  padding: 1rem;
  line-height: 1.5rem;
  font-family: inherit;
  outline: none;
  margin-top: 0.5rem;
`;

StyledInput.defaultProps = {
  autoComplete: 'off',
};

const StyledButton = styled.button`
  width: 100%;
  padding: 1rem;
  line-height: 1.5rem;
  transition: background-color 0.1s ease-in-out;
  text-align: left;
  &:hover {
    opacity: 1;
    background-color: whitesmoke;
  }
`;
StyledButton.defaultProps = {
  type: 'button',
};

const StyleEmpty = styled.div`
  width: 100%;
  padding: 1rem;
  line-height: 1.5rem;
  //border: 0.5px solid #e7e9ec;
  text-align: center;
  border-top: none;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const ButtonLayout = styled.div`
  position: absolute;
  right: 1rem;
  top: -2.5rem;
`;

const StyledIconButton = styled.button``;

StyledIconButton.defaultProps = {
  type: 'button',
};

const Layout = styled.div<{ focus: boolean }>`
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  visibility: ${({ focus }) => (focus ? 'visible' : 'hidden')};
  max-height: 15rem;
  box-shadow: 0px 0px 24px -2px rgba(82, 63, 105, 0.05);
  border-radius: 0 0 0.42rem 0.42rem;
  z-index: 100;
  border-top: none;
  overflow: auto;
`;

export {
  StyledInput,
  StyledButton,
  Container,
  StyleEmpty,
  Layout,
  ButtonLayout,
  StyledIconButton,
};
