import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import usTranslations from '../translations/en.json';
import krTranslations from '../translations/ko.json';
import zhTranslations from '../translations/zh.json';

const resources = {
  en: {
    translation: usTranslations,
  },
  ko: {
    translation: krTranslations,
  },
  zh: {
    translation: zhTranslations,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'ko',
  fallbackLng: 'ko',
  debug: true,
  interpolation: { escapeValue: true },
  returnObjects: true,
  returnEmptyString: true,
  returnNull: true,
});

export default i18n;
