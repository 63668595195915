import { ReactNode } from 'react';
import { lightTheme, darkTheme } from '@/styles/theme';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { useColorModeContext } from '@/contexts/ColorModeContext';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { Pretendard } from '@/styles/fonts';
import ComponentsOverrides from '@/theme/overrides';
import palette from '@/theme/palette';

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { colorMode } = useColorModeContext();

  const theme = createTheme({
    palette: palette(colorMode === 'light' ? 'light' : 'dark'),
    typography: {
      fontFamily: Pretendard.style.fontFamily,
      button: {
        textTransform: 'none',
        fontFamily: Pretendard.style.fontFamily,
      },
    },
    components: {
      MuiSkeleton: {
        defaultProps: {
          animation: 'wave',
        },
      },
    },
  });

  theme.components = ComponentsOverrides(theme);

  return (
    <MuiThemeProvider theme={theme}>
      <EmotionThemeProvider
        theme={colorMode === 'light' ? lightTheme : darkTheme}
        // theme={lightTheme}
      >
        {children}
      </EmotionThemeProvider>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
