import {
  StyledContent,
  StyledTable,
} from '@/components/Profile/SecondItem/ShopId/AuthInfo/styles';
import { useShopAuthInfo } from '@/hooks/apiHooks';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';

const ProofInfo = () => {
  const { name, employeeNumber } = useRecoilValue(MyInfoState);
  const { data, error } = useShopAuthInfo(employeeNumber);

  return (
    <div style={{ paddingTop: '3rem' }}>
      <StyledContent>인증 정보</StyledContent>

      <StyledTable>
        <table>
          <thead>
            <tr>
              <th>이름</th>
              <th>사번</th>
              <th>CID</th>
            </tr>
          </thead>
          <tbody>
            {data?.customerId ? (
              <tr>
                <td>{name}</td>
                <td>{employeeNumber}</td>
                <td>{data.customerId}</td>
              </tr>
            ) : (
              <td colSpan={3}>
                {error?.response?.data?.message
                  ? error?.response?.data?.message
                  : '-'}
              </td>
            )}
          </tbody>
        </table>
      </StyledTable>
    </div>
  );
};

export default ProofInfo;
