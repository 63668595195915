import styled from '@emotion/styled';

const StyledHeader = styled.div<{ open: boolean; isFnCo: boolean }>`
  position: fixed;
  display: flex;
  top: 0;
  width: 100%;
  // TODO: z-index 정책 정하기
  z-index: 1300;
  background-color: ${(props) =>
    props.open
      ? props.theme.colors.light
      : props.isFnCo
      ? '#f8a0ca'
      : 'var(--primary-color)'};
  transition: background-color 0.5s ease-in-out;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 65px;

  /* 열기 전 */
  .logo {
    display: block;
    width: 100px;
    height: 100%;
    background: ${({ isFnCo }) =>
        isFnCo
          ? `url(http://storage.fnf.co.kr/logos/fnco_white.png/dims/resize/200/optimize)`
          : 'url(http://storage.fnf.co.kr/logos/fnf_white.png/dims/resize/200/optimize)'}
      no-repeat 50%;
    background-size: auto 38%;
    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    opacity: 0.9 !important;
    font-size: 0.1em;
    text-indent: -9999px;
    color: transparent;
    overflow: hidden;
  }

  .btn-menu {
    position: relative;
    height: calc(1.5em + 1.65rem + 2px);
    width: calc(1.5em + 1.65rem + 2px);
    border-radius: 0.42rem;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    .svg-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2rem;
      height: 2rem;
      padding: 0;
      margin: 0;
      g [fill] {
        background-color: transparent;
        border-color: transparent;
      }
    }

    .svg-menu {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1) rotate(0);
      transition: transform 0.3s cubic-bezier(0.35, 0.755, 0.42, 0.95);
      g [fill] {
        fill: ${({ isFnCo, theme }) =>
          isFnCo ? theme.colors.white : '#3699ff'};
      }
    }
    .svg-close {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5) rotate(90deg);
      transition: transform 0.3s cubic-bezier(0.35, 0.755, 0.42, 0.95);
      g [fill] {
        fill: ${({ isFnCo, theme }) => (isFnCo ? '#f8a0ca' : '#3f4254')};
      }
    }

    &:hover {
      background-color: ${({ isFnCo, theme }) =>
        isFnCo ? 'rgba(255, 255, 255, 0.2)' : '#282f48'};
      border-color: #282f48;
      -webkit-transition: background-color 0.3s ease;
      transition: background-color 0.3s ease;
      g [fill] {
        -webkit-transition: fill 0.3s ease;
        transition: fill 0.3s ease;
      }
      .svg-menu {
        g [fill] {
          fill: ${({ isFnCo, theme }) =>
            isFnCo ? theme.colors.white : '#d1d3e0'};
        }
      }
      .svg-close {
        g [fill] {
          fill: #d1d3e0;
        }
      }
    }
  }

  /* 열린 후 */
  &.open {
    .logo {
      background-image: ${({ isFnCo }) =>
        isFnCo
          ? 'url(http://storage.fnf.co.kr/logos/fnco.png/dims/resize/200/optimize)'
          : 'url(http://storage.fnf.co.kr/logos/fnf.png/dims/resize/200/optimize)'};
      opacity: 1 !important;
    }

    .btn-menu {
      .svg-menu {
        opacity: 0;
        transition: opacity 0.3s ease;
        transform: translate(-50%, -50%) scale(0) rotate(90deg);
        transition: transform 0.3s cubic-bezier(0.35, 0.755, 0.42, 0.95);
      }
      .svg-close {
        opacity: 1;
        transition: opacity 0.3s ease;
        transform: translate(-50%, -50%) scale(1.5) rotate(0);
        transition: transform 0.3s cubic-bezier(0.35, 0.755, 0.42, 0.95);
      }
    }
    .profile {
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
      pointer-events: none;
    }
  }

  @media (max-width: 50em) {
    height: 50px;
  }
  padding: 0 25px;
  @media (max-width: 50em) {
    padding: 0 15px;
  }
`;

const RightContainer = styled.div<{ isFnCo: boolean }>`
  display: flex;
  background-color: ${({ isFnCo }) =>
    isFnCo ? '#f8a0ca' : `var(--primary-color)`};
  border-radius: 0.42rem;
  align-items: center;
`;

const StyledLink = styled.button`
  position: absolute;
  left: 50%;
  transform: translate(-50%);
`;
StyledLink.defaultProps = {
  type: 'button',
};

export { StyledHeader, RightContainer, StyledLink };
