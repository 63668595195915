import {
  Content,
  Layout,
  SecondItemLayout,
  Title,
  CustomDrawer,
  StyledIcon,
} from '@/components/Profile/SecondItem/styles';

import React, { ReactNode, useState } from 'react';
import { StyledTitle } from '@/components/LinkableDialog/styles';
import { DialogCloseButton } from '@/styles/styles';
import { CardMedia, Dialog, DialogContent } from '@mui/material';
import PersonalInfo from '@/components/Profile/SecondItem/PersonalInfo';
import Header from '@/components/Profile/SecondItem/Header';
import MailSignature from '@/components/Profile/SecondItem/MailSignature';
import PassWord from '@/components/Profile/SecondItem/PassWord';
import ProofEmp from '@/components/Profile/SecondItem/ProofEmp';
import ShopId from 'components/Profile/SecondItem/ShopId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBellOn,
  faUser,
  faSignature,
  faGear,
  faLock,
  faCalendar,
  faFileCertificate,
  faReceipt,
  faShoppingBag,
  faWifi,
  faLanguage,
} from '@fortawesome/pro-duotone-svg-icons';

import AlarmSettings from '@/components/Profile/SecondItem/AlarmSettings';
import Wifi from '@/components/Profile/SecondItem/Wifi';
import Language from '@/components/Profile/SecondItem/Language';
import Vacation from '@/components/Profile/SecondItem/Vacation';
import Salary from '@/components/Profile/SecondItem/Salary';

const SecondItem = () => {
  type SecondItemType = {
    icon: ReactNode;
    title: string;
    content: string;
    children: string | ReactNode;
  };

  const SecondList: SecondItemType[] = [
    {
      icon: <FontAwesomeIcon icon={faUser} size="lg" color={'gray'} />,
      title: '인사 정보',
      content: '인사 정보 확인 및 닉네임 변경',
      children: <PersonalInfo />,
    },
    {
      icon: (
        <FontAwesomeIcon
          icon={faSignature}
          size="lg"
          style={{ opacity: '0.8' }}
          color={'primary'}
        />
      ),
      title: '메일 서명',
      content: 'Outlook 메일 서명 설정',
      children: <MailSignature />,
    },
    {
      icon: <FontAwesomeIcon icon={faLanguage} size="lg" color={'primary'} />,
      title: '언어 설정',
      content: 'Language setting',
      children: <Language />,
    },
    {
      icon: <FontAwesomeIcon icon={faBellOn} size="lg" color={'primary'} />,
      title: '알림 설정',
      content: '포탈 웹/앱 알림 설정',
      children: <AlarmSettings />,
    },
    {
      icon: <FontAwesomeIcon icon={faLock} size="lg" color={'primary'} />,
      title: '비밀번호 관리',
      content: '내부 시스템 및 급여 비밀번호 관리',
      children: <PassWord />,
    },
    {
      icon: <FontAwesomeIcon icon={faCalendar} size="lg" color={'green'} />,
      title: '휴가 현황 조회',
      content: '연도별 개인 연차/월차 일수 조회',
      children: <Vacation />,
    },
    {
      icon: (
        <FontAwesomeIcon icon={faFileCertificate} size="lg" color={'green'} />
      ),
      title: '재직증명서',
      content: '재직증명서 발급 & 인쇄',
      children: <ProofEmp />,
    },
    {
      icon: <FontAwesomeIcon icon={faReceipt} size="lg" color={'green'} />,
      title: '급여명세서',
      content: '급여명세서 조회 및 연말정산 (iPayView)',
      children: <Salary />,
    },
    {
      icon: <FontAwesomeIcon icon={faShoppingBag} size="lg" color={'purple'} />,
      title: '자사몰 직원할인 인증',
      content: '자사 온라인 쇼핑몰 직원 할인을 위한 인증',
      children: <ShopId />,
    },
    {
      icon: <FontAwesomeIcon icon={faWifi} size="lg" color={'purple'} />,
      title: '방문자 와이파이 인증(큰길타워)',
      content: '본관 방문자의 경우 정보보안팀으로 문의',
      children: <Wifi />,
    },
  ];

  const [open, setOpen] = useState({
    drawerMode: false,
    idx: 0,
    open: false,
  });

  const handleOpen = (idx: number) => {
    setOpen({
      drawerMode: typeof SecondList[idx].children != 'string',
      idx: idx,
      open: true,
    });
  };

  const handleClose = () => {
    setOpen({ ...open, ...{ open: false } });
  };

  return (
    <>
      {SecondList.map(({ icon, content, title, children }, idx) => (
        <SecondItemLayout key={`second-${idx}`} onClick={() => handleOpen(idx)}>
          <StyledIcon>{icon}</StyledIcon>
          <Layout>
            <Title>{title}</Title>
            <Content>{content}</Content>
          </Layout>
        </SecondItemLayout>
      ))}
      <CustomDrawer open={open.drawerMode && open.open} onClose={handleClose}>
        <Header onClose={handleClose}>
          <div style={{}}>{SecondList[open.idx]?.children}</div>
        </Header>
      </CustomDrawer>
      <Dialog
        fullWidth
        maxWidth={'lg'}
        style={{ zIndex: 1500 }}
        open={!open.drawerMode && open.open}
        onClose={handleClose}
      >
        <StyledTitle>
          {SecondList[open.idx]?.title}
          <DialogCloseButton onClick={handleClose} />
        </StyledTitle>
        <DialogContent style={{ padding: '0' }} dividers>
          <CardMedia
            style={{
              height: '90vh',
            }}
            component={'iframe'}
            image={SecondList[open.idx]?.children as string}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SecondItem;
