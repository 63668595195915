import {
  Input,
  StyledPrintButton,
  Layout,
  InputLayout,
} from '@/components/Profile/SecondItem/ShopId/AuthShopId/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuthShopId } from '@/hooks/apiHooks';

import { AuthShopId } from '@/hooks/apiHooks';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';

const AuthShopId = () => {
  const { employeeNumber } = useRecoilValue(MyInfoState);
  const { register, handleSubmit, formState } = useForm<AuthShopId>();

  const onSubmit: SubmitHandler<AuthShopId> = (data) => {
    useAuthShopId({ ...data, employeeNumber })
      .then((_) => alert('인증되었습니다.'))
      .catch((e) =>
        alert(
          e?.response?.data?.message
            ? e?.response?.data?.message
            : '잠시후 다시 시도해 주세요.',
        ),
      );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Layout>
        <InputLayout>
          <Input
            {...register('onlineMallId', { required: true })}
            type={'text'}
            placeholder={'ID'}
          />
          <Input
            {...register('onlineMallPassword', { required: true })}
            type={'password'}
            placeholder={'비밀번호'}
          />
        </InputLayout>
        <StyledPrintButton disabled={!formState.isValid}>
          인증
        </StyledPrintButton>
      </Layout>
    </form>
  );
};

export default AuthShopId;
