import styled from '@emotion/styled';
import { SiteHeader } from '@/const/siteLoadMap';
import { maxBreakpoints, minBreakpoints } from '@/utils/mediaQuery';

const StyledRowLayout = styled.div<{ depth: number }>`
  display: flex;
  flex-direction: column;
  font-size: ${({ depth }) => `${depth === 0 ? '1.9rem' : '1rem'}`};
`;

const StyledRowText = styled.span<{ url?: string; depth: number }>`
  width: fit-content;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: ${({ depth }) => (depth >= 1 ? `${depth * 1.25}rem` : '0')};
  margin-bottom: ${({ depth }) => (depth >= 1 ? '0' : '10px')};
  font-family: var(--font-Pretendard);
  font-weight: ${({ depth }) => (depth >= 1 ? '' : '600 !important')};
  border-left: ${({ depth }) => (depth >= 1 ? '1px solid' : '')};
  border-color: ${({ theme }) => theme.colors.dark65};
  cursor: ${({ url }) => (url ? 'pointer' : '')};
  > span {
    font-weight: ${({ depth }) => (depth >= 1 ? '' : '600 !important')};
  }
  a,
  > span {
    position: relative;
    color: ${({ depth, theme }) =>
      depth >= 1 ? theme.colors.dark65 : theme.colors.dark};
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0px;
      width: 100%;
      height: 1px;
      bottom: 0px;
      transform: scaleX(0);
      will-change: transform;
      transform-origin: 100% 0px;
      background: rgba(23, 23, 25, 0.5);
      transition: transform 0.3s ease-in-out 0s;
    }
    &:hover {
      color: ${({ url, theme }) => (url ? theme.colors.dark : '')};
      &:after {
        transform: ${({ url }) => (url ? 'scaleX(1)' : '')};
        transform-origin: 0 0;
      }
    }
  }
`;

const StyledAnchor = styled.a<{ url?: string }>`
  width: fit-content;
  font-family: inherit;
  cursor: ${({ url }) => (url ? 'pointer' : '')};
  position: relative;
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0px;
    width: 100%;
    height: 1px;
    bottom: 0px;
    transform: scaleX(0);
    will-change: transform;
    transform-origin: 100% 0px;
    background: rgba(23, 23, 25, 0.5);
    transition: transform 0.3s ease-in-out 0s;
  }
  &:hover {
    color: ${({ url, theme }) => (url ? theme.colors.dark : '')};
    &:after {
      transform: ${({ url }) => (url ? 'scaleX(1)' : '')};
      transform-origin: 0 0;
    }
  }
`;

const StyledMenuLayout = styled.div`
  height: 80vh;
  padding: 10rem 6rem 5rem 10rem;
  background-color: ${({ theme }) => theme.colors.light};
  overflow-y: scroll;
  @media screen and (${maxBreakpoints.xs}) {
    padding-top: 8.75rem;
  }
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    '${SiteHeader.OurSites} ${SiteHeader.FnfNews} ${SiteHeader.Approval}'
    '${SiteHeader.ForWork} ${SiteHeader.FnfNews} ${SiteHeader.Approval}';
  grid-template-areas:
    '${SiteHeader.ForWork} ${SiteHeader.FnfNews} ${SiteHeader.Approval}'
    '${SiteHeader.BugReport}';
  gap: 2rem;

  @media screen and (${minBreakpoints.xs}) and (${maxBreakpoints.s}) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
      '${SiteHeader.FnfNews} ${SiteHeader.Approval}'
      '${SiteHeader.OurSites} ${SiteHeader.ForWork}'
      '- ${SiteHeader.BugReport}';
  }

  @media screen and (${maxBreakpoints.xs}) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-template-areas:
      '${SiteHeader.FnfNews}'
      '${SiteHeader.Approval}'
      '${SiteHeader.OurSites}'
      '${SiteHeader.ForWork}'
      '${SiteHeader.BugReport}';
  }
`;

const StyledRowContainer = styled.div<{ gridArea: string }>`
  grid-area: ${({ gridArea }) => gridArea};
  display: flex;
  flex-direction: column;
  gap: 1rem;

  // 오류불편신고
  &.BugReport > div {
    align-items: flex-start;
    font-size: 1.15rem !important;
  }
`;

const SelectButton = styled.button<{ selected: boolean }>`
  margin-left: 0.5rem;
  border-radius: 1rem;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.primary : theme.colors.white};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.black50};
  transition: all 0.2s ease-in-out;
`;

const StyledContent = styled.a`
  display: flex;
  padding: 1rem 2rem;
  border-radius: 0.42rem;
`;

export {
  StyledAnchor,
  StyledRowContainer,
  StyledRowLayout,
  StyledMenuLayout,
  StyledRowText,
  SelectButton,
  StyledContent,
};
