export const minBreakpoints = {
  xs: '(min-width: 678px)',
  s: '(min-width: 992px)',
  md: '(min-width: 1200px)',
  lg: '(min-width: 1400px)',
  xl: '(min-width: 1512px)',
};

export const maxBreakpoints = {
  xs: '(max-width: 677px)',
  s: '(max-width: 991px)',
  md: '(max-width: 1199px)',
  lg: '(min-width: 1399px)',
  xl: '(max-width: 1511px)',
};
