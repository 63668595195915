import React from 'react';
import {
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
  DialogContainer,
  DialogLeftButton,
  DialogRightButton,
  StyledCloseIconButton,
} from '@/components/CustomDialog/styles';
import Image from 'next/image';
import {
  Breakpoint,
  DialogProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';

export type DialogType = 'confirm' | 'alert' | 'alert-action';

export type DialogOptions = {
  id?: string;
  title?: React.ReactNode;
  message?: React.ReactNode;
  mobileContent?: React.ReactNode;
  desktopContent?: React.ReactNode;
  type?: DialogType;
  invisibleButton?: boolean;
  confirmButtonTitle?: string;
  dismissButtonTitle?: string;
  onDismiss?: () => Promise<void> | void;
  onConfirm?: () => Promise<void> | void;
  onAfterClose?: () => void;
  onBackdropClose?: () => void;
  dialogProps?: DialogProps;
  containerMaxWidth?: number;
  containerMinWidth?: number;
  pcBreakPoint?: Breakpoint | number;
  forceToFront?: boolean;
  blockBackdrop?: boolean;
};

export type HandleDialog = (options?: DialogOptions) => void;

type P = {
  open: boolean;
  onClose: () => void;
  onClickCancel: () => void;
  onClickOk: () => void;
  dialogOptions: DialogOptions;
};

const CustomDialog = ({
  open,
  onClose,
  onClickCancel,
  onClickOk,
  dialogOptions,
}: P) => {
  const {
    type,
    title,
    message,
    mobileContent,
    desktopContent,
    dismissButtonTitle,
    confirmButtonTitle,
    dialogProps,
    containerMaxWidth,
    containerMinWidth,
    pcBreakPoint,
    forceToFront,
    blockBackdrop,
    onBackdropClose,
    invisibleButton = false,
  } = dialogOptions;
  const theme = useTheme();
  const isDesktop = useMediaQuery(
    pcBreakPoint ? theme.breakpoints.up(pcBreakPoint) : `(${769})`, //minBreakpoints.pc: 769
  );
  const isAlert = type === 'alert' || type === 'alert-action';
  const isConfirm = type === 'confirm';
  const contentNode =
    desktopContent || mobileContent
      ? isDesktop
        ? desktopContent
        : mobileContent
      : message;

  const handleCancelOnClose = (handler: () => void) => {
    handler();
    onBackdropClose?.();
  };

  return (
    <>
      <DialogContainer
        {...dialogProps}
        className={`${forceToFront && 'high-z-index'}`}
        containerMaxWidth={containerMaxWidth}
        containerMinWidth={containerMinWidth}
        isDesktop={isDesktop}
        open={open}
        onClose={() =>
          blockBackdrop === true ? null : handleCancelOnClose(onClose)
        }
      >
        <StyledCloseIconButton
          isDesktop={isDesktop}
          onClick={() => handleCancelOnClose(onClose)}
        >
          <Image
            src={'/images/close.svg'}
            alt={'dialog-close'}
            width={11}
            height={11}
          />
        </StyledCloseIconButton>
        {title && <CustomDialogTitle>{title}</CustomDialogTitle>}
        <CustomDialogContent isDesktop={isDesktop}>
          {contentNode}
        </CustomDialogContent>
        {!invisibleButton && (
          <CustomDialogActions>
            {isConfirm && (
              <DialogLeftButton isDesktop={isDesktop} onClick={onClickCancel}>
                {dismissButtonTitle}
              </DialogLeftButton>
            )}
            <DialogRightButton
              isDesktop={isDesktop}
              isAlert={isAlert}
              dialogType={type}
              onClick={onClickOk}
            >
              {confirmButtonTitle}
            </DialogRightButton>
          </CustomDialogActions>
        )}
      </DialogContainer>
    </>
  );
};

export default CustomDialog;
