import { Theme } from '@mui/material/styles';
import { CheckboxProps } from '@mui/material';
import { CheckboxIcon, CheckboxCheckedIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Checkbox(theme: Theme) {
  return {
    MuiCheckbox: {
      defaultProps: {
        icon: <CheckboxIcon />,
        checkedIcon: <CheckboxCheckedIcon />,
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: CheckboxProps }) => ({
          padding: theme.spacing(0.5),
          ...(ownerState.size === 'medium' && {
            '& svg': { width: 20, height: 20 },
          }),
        }),
      },
    },
  };
}
