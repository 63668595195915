import styled from '@emotion/styled';

const Layout = styled.div`
  padding: 2rem;
  width: 400px;
`;

const StyledTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
`;

const StyledContent = styled.div`
  margin-top: 1rem;
  padding-bottom: 1rem;
`;

const StyledButton = styled.button`
  margin-top: 1rem;
  border-radius: 0.42rem;
  background-color: ${({ theme }) => theme.colors.dark};
  padding: 1rem 1rem;
  color: white;
  width: 100%;
  :disabled {
    opacity: 0.3;
  }
`;

export { Layout, StyledTitle, StyledContent, StyledButton };
