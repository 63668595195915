import styled from '@emotion/styled';

const StyledLayout = styled.div<{ v2Version: boolean }>`
  padding-top: ${({ v2Version }) => !v2Version && '65px'};
  @media (max-width: 50em) {
    padding-top: ${({ v2Version }) => !v2Version && '50px'};
  }
  min-width: 375px;
  min-height: 100vh;
  height: 100vh;
  background-color: ${({ v2Version }) =>
    v2Version ? 'white' : 'var(--background-color)'};
  overflow: ${({ v2Version }) => !v2Version && 'hidden'};
`;

export { StyledLayout };
