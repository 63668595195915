import {
  StyledHeader,
  RightContainer,
  StyledLink,
} from '@/components/Header/styles';
import { useState } from 'react';
import MenuDrawer from '@/components/MenuDrawer';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import Profile from '@/components/Profile';
import { FnCoCompanyCode } from '@/utils/const';

const Header = () => {
  const { companyCode } = useRecoilValue(MyInfoState);
  const isFnCo = FnCoCompanyCode.includes(companyCode);

  const router = useRouter();
  const isHome = router.route === '/';

  const [open, setOpen] = useState(false);
  const handleOnClick = () => setOpen((prev) => !prev);
  const handleClose = () => setOpen(false);

  const handleRefresh = () => {
    router.route !== '/' ? router.push('/') : router.reload();
  };

  return (
    <StyledHeader
      open={open}
      className={`${open ? 'open' : ''}`}
      isFnCo={isFnCo}
    >
      {isHome && (
        <MenuDrawer
          open={open}
          handleOnClick={handleOnClick}
          handleClose={handleClose}
        />
      )}
      <StyledLink onClick={handleRefresh} className="logo">
        F&F
      </StyledLink>
      <RightContainer isFnCo={isFnCo} className={'profile'}>
        {isHome && <Profile />}
      </RightContainer>
    </StyledHeader>
  );
};

export default Header;
