import { Theme } from '@mui/material/styles';
import Checkbox from './Checkbox';
import Menu from './Menu';
import IconButton from './IconButton';
import Button from './Button';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    Checkbox(theme),
    Menu(theme),
    IconButton(theme),
    Button(theme),
  );
}
