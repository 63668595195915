import {
  RowLayout,
  Layout,
  StyledContent,
  StyledTitle,
  StyledSubmitButton,
} from '@/components/Profile/SecondItem/ProofEmp/styles';

import { useGetCertifyCode } from '@/hooks/apiHooks';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';

import CertifySMS from '@/components/Profile/SecondItem/ProofEmp/CertifySMS';
import HistoryProof from '@/components/Profile/SecondItem/ProofEmp/HistoryProof';

const ProofEmp = () => {
  const { phoneMobile } = useRecoilValue(MyInfoState);
  const [verifyId, setVerifyId] = useState('');

  const handleGetCertifyCode = () => {
    useGetCertifyCode(phoneMobile)
      .then((data) => {
        setVerifyId(data.data.smsVerificationId);
      })
      .catch((e) => alert(e));
  };

  const handleInitVerifyId = () => {
    setVerifyId('');
  };

  return (
    <Layout>
      <StyledTitle>재직증명서 발급</StyledTitle>
      <StyledContent style={{ color: '#19c5be', backgroundColor: '#c9f7f4' }}>
        발급된 재직증명서는 이력에 기록됩니다.
      </StyledContent>
      <RowLayout>
        {!verifyId ? (
          <StyledSubmitButton onClick={handleGetCertifyCode}>
            인증번호 문자 발송
          </StyledSubmitButton>
        ) : (
          <CertifySMS
            smsVerificationId={verifyId}
            handleInit={handleInitVerifyId}
          />
        )}
      </RowLayout>
      <HistoryProof />
    </Layout>
  );
};

export default ProofEmp;
