import {
  Layout,
  StyledTitle,
  StyledContent,
} from '@/components/Profile/SecondItem/Wifi/styles';

import Image from 'next/image';
import React from 'react';

const Wifi = () => {
  return (
    <Layout>
      <StyledTitle>방문자 와이파이 인증(큰길타워)</StyledTitle>
      <StyledContent>
        큰길타워 방문자의 WiFi 사용이 필요한 경우
        <br /> 아래의 버튼을 눌러 나오는 화면에서 인증 후 사용 가능합니다.
        <br />
        문의사항은 정보보안팀으로 연락 바랍니다.
      </StyledContent>
      <a
        href={'https://172.20.1.2:8443/wifi.jsp'}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'black',
          color: 'white',
          fontSize: '16px',
          width: '100%',
          padding: '12px 16px',
          borderRadius: '6px',
          gap: '4px',
        }}
        target={'_blank'}
        rel={'noreferrer'}
      >
        <Image alt={'link'} src={'/images/link.svg'} width={20} height={20} />
        <div>인증화면 열기</div>
      </a>
      <div style={{ fontSize: '14px' }}>
        임직원은{' '}
        <a
          href={
            'https://fnf.atlassian.net/servicedesk/customer/portal/50/group/158/create/480'
          }
          style={{ color: '#06C', textDecoration: 'underline' }}
          target={'_blank'}
          rel={'noreferrer'}
        >
          WiFi 사용 신청
        </a>
        을 통해 기기 등록 후 사용해 주세요.
      </div>
    </Layout>
  );
};
export default Wifi;
