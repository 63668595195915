import {
  APIX_KEY,
  JWT_ACCESS_TOKEN,
  JWT_REFRESH_TOKEN,
  MS_ACCESS_TOKEN,
  MS_REFRESH_TOKEN,
  twoWeek,
  USER_NAME,
} from '@/const/tokens';
import { ApiTokenType, PortalToken, TokenInfo } from '@/types/api';
import { Cookies } from 'react-cookie';
import { addSeconds } from 'date-fns';
import { fetchApi } from '@/utils/api/customAxios';
import { MSLoginResponse } from '@/hooks/apiHooks';
import serverSideCookies from 'next-cookies';

const useCookieDefaultOption = () => {
  const vercelEnv = process.env.VERCEL_ENV;
  const isProduction = vercelEnv === 'production';

  return {
    path: '/', // 모든 경로에서 쿠키 접근 가능
    httpOnly: isProduction,
  };
};

export const setCookie = (
  name: string,
  value: unknown,
  expires?: string | number,
) => {
  const cookie = new Cookies();
  const options = useCookieDefaultOption();
  let expiresOption: object = {};

  switch (typeof expires) {
    case 'number':
      expiresOption = {
        expires: addSeconds(new Date(), expires),
      };
      break;
    case 'string':
      expiresOption = {
        expires: new Date(expires),
      };
      break;
    default:
      break;
  }

  cookie.set(name, value, { ...options, ...expiresOption });
};

export const getCookie = <T>(name: string) => {
  const cookie = new Cookies();
  return cookie.get<T>(name);
};

export const getInitToken = async () => {
  const msRefreshToken = getCookie(MS_REFRESH_TOKEN);

  if (!msRefreshToken) window.location.href = '/login';

  await fetchApi<{ data: MSLoginResponse }>({
    method: 'GET',
    url: `/api/auth/azure/token/refresh`,
    apiTokenType: ApiTokenType.MS,
    extraHeaders: {
      Authorization: `Bearer ${msRefreshToken}`,
    },
  }).then((res) => {
    const { access_token, refresh_token, expires_at } = res.data;
    setCookie(MS_ACCESS_TOKEN, access_token, expires_at); // FIXME: 이거 지금 서버에서 string으로 내려오고 있음
    setCookie(MS_REFRESH_TOKEN, refresh_token);
  });
  // getCookie();
  // console.log(cookies.getAll());
  // TODO: 여기서부터, access 토큰이랑 refresh 토큰 가지고 재발급 처리
  // TOKEN_KEY_SET.forEach((keyName) => {
  // const getByCookies = cookies.get<string | undefined>(keyName);
  // console.log(getByCookies);
  // setCookie(keyName, getByCookies);
  // });
};

export const setTokenInfo = (
  msTokenInfo: MSLoginResponse,
  jwtTokenInfo: PortalToken,
  tokenInfo: TokenInfo,
) => {
  const { msAzure, apix } = tokenInfo;
  const { userId } = msAzure;
  const { key: apixKey, expiresAt: apixExpiresAt } = apix;

  setCookie(USER_NAME, userId, apixExpiresAt);
  setCookie(APIX_KEY, apixKey, apixExpiresAt);

  setCookie(MS_ACCESS_TOKEN, msTokenInfo.access_token, msTokenInfo.expires_at);
  setCookie(MS_REFRESH_TOKEN, msTokenInfo.refresh_token, twoWeek);

  setCookie(
    JWT_ACCESS_TOKEN,
    jwtTokenInfo.data.access_token,
    jwtTokenInfo.data.expires_in,
  );
  setCookie(
    JWT_REFRESH_TOKEN,
    jwtTokenInfo.data.refresh_token,
    jwtTokenInfo.data.refresh_expires_in,
  );
};

export const removeCookie = (name: string) => {
  const cookie = new Cookies();
  cookie.remove(name);
};

export const setOauthToken = (url: string) => {
  return (
    url +
    (url.includes('?') ? '&oauthToken=' : '?oauthToken=') +
    getCookie(MS_ACCESS_TOKEN)
  );
};

export const setJwtToken = (url: string) => {
  return (
    url +
    (url.includes('?') ? '&portalToken=' : '?portalToken=') +
    getCookie(JWT_ACCESS_TOKEN)
  );
};
