import { StyledTable } from '@/components/Profile/SecondItem/ProofEmp/HistoryProof/styles';
import { useHistoryEmpProof } from '@/hooks/apiHooks';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';

const History = () => {
  const { employeeNumber } = useRecoilValue(MyInfoState);
  const { data } = useHistoryEmpProof({
    employeeNumber,
    listSize: 500,
    currentPage: 1,
  });

  return (
    <StyledTable>
      <table>
        <thead>
          <tr>
            <th>발급번호</th>
            <th style={{ width: '50%' }}>용도</th>
            <th>발급일</th>
          </tr>
        </thead>
        <tbody>
          {data && data?.length !== 0 ? (
            data.map((his, idx) => (
              <tr key={`EmpHistory-${idx}`}>
                <td>{his.cartNumber}</td>
                <td>{his.ouputReason}</td>
                <td>{his.createDate}</td>
              </tr>
            ))
          ) : (
            <td className="noData" colSpan={3}>
              조회된 내역이 없습니다.
            </td>
          )}
        </tbody>
      </table>
    </StyledTable>
  );
};

export default History;
