import styled from '@emotion/styled';
import { Drawer } from '@mui/material';

const SecondItemLayout = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  padding: 0 1rem;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const Title = styled.div`
  display: flex;
  color: var(--text-primary-color);
  font-size: 1rem;
  font-weight: 600;
`;

const Content = styled.div`
  color: var(--text-secondary-color);
  text-align: start;
  font-size: 0.75rem;
`;
const CustomDrawer = styled(Drawer)`
  position: fixed;
  z-index: 1500;
`;
CustomDrawer.defaultProps = {
  anchor: 'right',
};

const StyledIcon = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.42rem;
  color: ${({ theme }) => theme.colors.primary};
`;

export { Content, Layout, SecondItemLayout, Title, CustomDrawer, StyledIcon };
