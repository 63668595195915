import styled from '@emotion/styled';

const StyledContent = styled.div`
  display: flex;
  flex: 1;
  max-width: 300px;
  color: ${({ theme }) => theme.colors.gray};
`;

const StyledSubContent = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.gray};
  font-size: 0.75rem;
`;

const StyledContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: left;
  gap: 1rem;
  justify-content: space-between;
  font-size: 1rem;
`;

const StyledRowLayout = styled.div<{ read: boolean; mobileMode?: boolean }>`
  border-radius: 0.42rem;
  display: flex;
  width: 100%;
  padding: 1rem 1rem;
  cursor: pointer;
  background-color: ${({ theme, read }) =>
    read ? theme.colors.white : theme.colors.light};
  &:hover {
    background-color: ${({ theme, mobileMode }) =>
      !mobileMode && theme.colors.light};
  }
`;

const StyledReadButton = styled.button`
  &:hover {
    text-decoration-line: underline;
  }
`;
StyledReadButton.defaultProps = {
  type: 'button',
};

export {
  StyledContent,
  StyledSubContent,
  StyledContentLayout,
  StyledRowLayout,
  StyledReadButton,
};
