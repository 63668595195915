import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import styled from '@emotion/styled';
import { DialogType } from '@/components/CustomDialog/index';

export const DialogContainer = styled(Dialog, {
  shouldForwardProp: (prop) =>
    prop !== 'isDesktop' &&
    prop !== 'containerMaxWidth' &&
    prop !== 'containerMinWidth',
})<{
  isDesktop: boolean;
  containerMaxWidth: number | undefined;
  containerMinWidth: number | undefined;
}>`
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.25rem;
  font-weight: 500;

  & .MuiPaper-root {
    position: relative;
    border-radius: 8px;
    color: black;
    box-shadow: none;

    ${({ isDesktop, containerMaxWidth, containerMinWidth }) =>
      isDesktop
        ? `
      margin: 0 2rem;
      padding: 3.5rem 1.5rem 2rem 1.5rem;
      width: calc(100% - 4rem);
      max-width: ${containerMaxWidth ? `${containerMaxWidth}px` : '27.375rem'};
      min-width: ${containerMinWidth ? `${containerMinWidth}px` : 'unset'};
    `
        : `
      width: 311px;
      padding: 48px 20px 32px 20px;
    `};
  }
  & .MuiBackdrop-root {
    background-color: ${({ isDesktop }) =>
      isDesktop ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.24)'};
  }
`;

export const CustomDialogTitle = styled(DialogTitle)`
  margin: 0 auto 0.75rem;
  padding: 0;
  font-size: 16px;
  line-height: 1.5rem;
  font-weight: 500;
  white-space: pre-wrap;
  text-align: center;
`;

export const CustomDialogContent = styled(DialogContent)<{
  isDesktop: boolean;
}>`
  width: 100%;
  margin: 0 auto;
  font-weight: 400;
  padding: 0;
  white-space: pre-wrap;
  text-align: center;
  line-height: ${(props) => (props.isDesktop ? '24px' : '20px')};
  font-size: ${(props) => (props.isDesktop ? '16px' : '14px')};

  .MuiDialogTitle-root + & {
    color: black;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const StyledCloseIconButton = styled.button<{
  isDesktop: boolean;
}>`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 6.5px;
  min-width: unset;
  overflow: hidden;
`;

export const CustomDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 32px;
  padding: 0;
  &.MuiDialogActions-root > :not(:first-of-type) {
    margin-left: 0;
  }
`;

export const DialogLeftButton = styled.button<{
  isDesktop: boolean;
}>`
  height: 3rem;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? '16px' : '14px')};
  border: 1px solid #e7e9ec;
  color: black;

  &[type='button'] {
    background-color: white;
  }
`;
DialogLeftButton.defaultProps = {
  type: 'button',
};

export const DialogRightButton = styled.button<{
  isDesktop: boolean;
  isAlert: boolean;
  dialogType: DialogType | undefined;
}>`
  height: 3rem;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: ${(props) => (props.isDesktop ? '16px' : '14px')};
  max-width: ${(props) =>
    props.isDesktop && props.isAlert ? '9.25rem' : 'auto'};
  border: 1px solid
    ${(props) => (props.dialogType === 'alert' ? '#e7e9ec' : 'black')};
  color: ${(props) => (props.dialogType === 'alert' ? 'black' : 'white')};

  &[type='button'] {
    background-color: ${(props) =>
      props.dialogType === 'alert' ? 'white' : 'black'};
  }
`;
DialogRightButton.defaultProps = {
  type: 'button',
};
