import { initializeApp } from 'firebase/app';
import { FirebaseOptions } from '@firebase/app-types';
import process from 'process';
import { deleteToken, getMessaging, isSupported } from '@firebase/messaging';
import { getCookie, removeCookie } from '@/utils/tokens';
import { FIREBASE_TOKEN } from '@/const/tokens';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTO_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export const requestPermission = async () => {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('알림 권한이 허용됨');
      return true;
    } else {
      console.log('알림 권한 허용 안됨');
      return false;
    }
  });
};

export const disableMessage = async () => {
  try {
    const messaging = getMessaging(firebaseApp);
    if (getCookie(FIREBASE_TOKEN)) {
      removeCookie(FIREBASE_TOKEN);
      await deleteToken(messaging);
    }
  } catch (e) {
    console.log(e);
  }
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
