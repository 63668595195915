import React, { ReactNode } from 'react';
import {
  Layout,
  StyledBack,
  StyledHeader,
} from '@/components/Profile/SecondItem/Header/styles';
import Image from 'next/image';

type P = {
  children: ReactNode;
  onClose: () => void;
};
const Header = ({ children, onClose }: P) => {
  return (
    <>
      <StyledHeader>
        <StyledBack onClick={onClose}>
          <Image alt={'back'} src={'/images/back.svg'} width={16} height={16} />
        </StyledBack>
      </StyledHeader>
      <Layout>{children}</Layout>
    </>
  );
};

export default Header;
