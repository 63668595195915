import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;

const StyledLayout = styled.div`
  .MuiAccordionSummary-content {
    margin: 0;
    &.Mui-expanded {
      margin: 0;
    }
  }
  .MuiAccordionDetails-root {
    padding: 0 16px;
    min-height: 48px;
  }
  .MuiButtonBase-root {
    &.MuiAccordionSummary-root {
      &.Mui-expanded {
        min-height: 48px;
      }
    }
  }
`;

export { StyledLayout, Container };
