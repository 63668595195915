import { AxiosInstance, Method } from 'axios';

import {
  APIErrorHandlingOptions,
  processApiError,
} from '@/utils/api/ApiDefault';
import { ApiTokenType } from '@/types/api';
import * as process from 'process';
import { jwtInstance } from '@/utils/api/jwt/instance';

export const fetchApi = async <T = unknown>({
  instance = jwtInstance,
  method,
  url,
  baseURL = process.env.NEXT_PUBLIC_API_URL,
  data,
  apiTokenType = ApiTokenType.APIX,
  isOldPortal = false,
  deviceCode,
  extraHeaders,
  errorHandlingOptions,
}: {
  instance?: AxiosInstance;
  method: Method;
  url: string;
  isOldPortal?: boolean;
  baseURL?: string;
  data?: Record<string, unknown>;
  apiTokenType?: ApiTokenType;
  deviceCode?: string;
  extraHeaders?: Record<string, unknown>;
  errorHandlingOptions?: APIErrorHandlingOptions;
}): Promise<T> => {
  const headers: Record<string, string> = {};
  if (deviceCode) headers.deviceCode = deviceCode;
  if (extraHeaders) Object.assign(headers, extraHeaders);

  Object.assign(headers, {
    api_token_type: apiTokenType,
  });

  if (process.env.NODE_ENV === 'development') {
    console.log(`👉 [${method}] ${url}`);
  }

  return await instance<T>({
    method,
    url: isOldPortal ? `/api/v1/proxy/old-portal${url}` : url,
    data,
    baseURL,
    headers,
  })
    .then((res) => res.data)
    .catch((err) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(`❌ [${method}] ${url}`);
      }
      throw processApiError(err, errorHandlingOptions);
    });
};
