import styled from '@emotion/styled';

const StyledTitle = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
`;

export { StyledTitle };
