import { Drawer } from '@mui/material';
import {
  approvalLoadMaps,
  bugReportLoadMaps,
  forWorkLoadMaps,
  newLoadMaps,
  ourSitesLoadMaps,
  SiteHeader,
  ViewType,
} from '@/const/siteLoadMap';
import FullPageIframeModal from '@/components/FullPageIframeModal';
import {
  SelectButton,
  StyledAnchor,
  StyledMenuLayout,
  StyledRowContainer,
  StyledRowLayout,
  StyledRowText,
  StyledContent,
} from '@/components/MenuDrawer/styles';
import SVG from '../SVG/SVG';
import { MenuData } from '@/types/api';
import { useMenu } from '@/hooks/apiHooks';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { useState } from 'react';

type P = {
  open: boolean;
  handleOnClick: () => void;
  handleClose: () => void;
};

type SiteWithDepth = MenuData & {
  depth: number;
  brother: MenuData[];
};

const RenderByViewType = (props: SiteWithDepth) => {
  const { action, brother, depth, link, name } = props;
  const [selectedSite, setSelectedSite] = useState<SiteWithDepth>(props);
  //  action?: 'layer' | 'modal' | 'newTab';
  const makeViewType = (r?: string) => {
    switch (r) {
      case 'layer':
        return ViewType.Modal;
      case 'modal':
        return ViewType.Modal;
      case 'newTab':
        return ViewType.Blank;
      default:
        return ViewType.None;
    }
  };
  const handleBrotherClick = (m: SiteWithDepth) => () => setSelectedSite(m);

  const DialogTitle = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {/*<div>{selectedSite.name}</div>*/}
        {brother
          ?.filter(
            (v) => v.isDisplay && makeViewType(v.action) === ViewType.Modal,
          )
          ?.map((v) => (
            <SelectButton
              selected={v.name === selectedSite.name}
              key={v.name}
              onClick={handleBrotherClick({
                ...v,
                depth: depth,
                brother: brother,
              })}
            >
              {v.name}
            </SelectButton>
          ))}
      </div>
    );
  };

  const viewType = makeViewType(action);

  switch (viewType) {
    case ViewType.Blank: {
      if (link?.includes('dashff.fnf.co.kr')) {
        return (
          <StyledContent
            href={link}
            target={'_blank'}
            rel={'noreferrer'}
            style={{
              color: '#f5505f',
              backgroundColor: '#f4e6eb',
            }}
          >
            {name}
          </StyledContent>
        );
      } else {
        return (
          <StyledAnchor href={link} target={'_blank'} rel={'noreferrer'}>
            {name}
          </StyledAnchor>
        );
      }
    }
    case ViewType.Modal:
      return (
        <FullPageIframeModal
          iframeId={name.replace('/[/s|\\/]/g', '_')}
          style={{
            fontWeight: 'normal',
            fontSize: 'inherit',
            minWidth: 'max-content',
          }}
          trigger={name}
          dialogTitle={<DialogTitle />}
          url={selectedSite.link || ''}
          isMenuLayer
        />
      );
    case ViewType.None:
      return <div style={{ opacity: '0.5' }}>{name}</div>;
    default:
      return <div />;
  }
};

const SiteRow = ({
  name,
  childData,
  depth,
  link,
  action,
  isDisplay,
  brother,
}: SiteWithDepth) => (
  <StyledRowLayout title={name} depth={depth}>
    {isDisplay && (
      <StyledRowText depth={depth} url={link} className="menu-item">
        <RenderByViewType
          isDisplay={isDisplay}
          link={link || ''}
          name={name}
          childData={childData}
          action={action}
          depth={depth}
          brother={brother}
        />
      </StyledRowText>
    )}
    {(childData || []).map((c) => (
      <SiteRow {...c} depth={depth + 1} key={c.name} brother={childData} />
    ))}
  </StyledRowLayout>
);

const MenuDrawer = ({ open, handleOnClick, handleClose }: P) => {
  const as = [
    ourSitesLoadMaps,
    forWorkLoadMaps,
    newLoadMaps,
    approvalLoadMaps,
    bugReportLoadMaps,
  ];

  const myInfo = useRecoilValue(MyInfoState);
  const { data: menuData } = useMenu(myInfo);

  const filter = (gridNumber: number) =>
    (menuData?.data || []).filter((d) => d.gridNumber === gridNumber);

  // gridNumber: 0 -> 좌측상단
  const filterOurSites = filter(0);
  // gridNumber: 1 -> 좌측하단
  const filterForWork = filter(1);
  // gridNumber: 2 -> 중앙
  const filterNews = filter(2);
  // gridNumber: 3 -> 우측
  const filterApproval = filter(3);

  const loadMaps = new Map([
    [SiteHeader.OurSites, filterOurSites],
    [SiteHeader.ForWork, filterForWork],
    [SiteHeader.FnfNews, filterNews],
    [SiteHeader.Approval, filterApproval],
    [SiteHeader.BugReport, bugReportLoadMaps],
  ]);

  return (
    <>
      <button type={'button'} onClick={handleOnClick} className="btn-menu">
        <SVG
          name="mega-menu"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          className="svg-menu svg-icon"
        />
        <SVG
          name="mega-close"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          className="svg-close svg-icon"
        />
      </button>
      <Drawer anchor={'top'} open={open} onClose={handleClose}>
        <StyledMenuLayout>
          {Array.from(loadMaps.entries()).map(([key, value]) => (
            <StyledRowContainer gridArea={key} key={key} className={key}>
              {value.map((c) => (
                <SiteRow {...c} depth={0} key={c.name} brother={[]} />
              ))}
            </StyledRowContainer>
          ))}
        </StyledMenuLayout>
      </Drawer>
    </>
  );
};

export default MenuDrawer;
