import {
  Layout,
  StyledTitle,
  RowLayout,
  HeaderLayout,
  StyledLink,
  StyledContent,
} from '@/components/Profile/SecondItem/MailSignature/styles';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import React from 'react';
import { SignatureItem } from '@/components/Profile/SecondItem/MailSignature/SignatureItem';
import SloganItem from '@/components/Profile/SecondItem/MailSignature/SloganItem';
import { FnCoCompanyCode } from '@/utils/const';

enum Color {
  Pink = '#f284ae',
  Green = '#527f6a',
  Blue = '#18345b',
}

type SignatureCard = {
  title: string;
  color: Color;
  logo: boolean;
};

const SignatureFNFCards: SignatureCard[] = [
  {
    title: 'Type 1. 로고 있는 서명',
    color: Color.Blue,
    logo: true,
  },
  { title: 'Type 2. 로고 없는 서명', color: Color.Blue, logo: false },
];

const SignatureFNCoCards: SignatureCard[] = [
  { title: 'Type 1-1. 로고 있는 서명 (분홍)', color: Color.Pink, logo: true },
  {
    title: 'Type 1-2. 로고 있는 서명 (초록)',
    color: Color.Green,
    logo: true,
  },
  {
    title: 'Type 2-1. 로고 없는 서명 (분홍)',
    color: Color.Pink,
    logo: false,
  },
  {
    title: 'Type 2-2. 로고 없는 서명 (초록)',
    color: Color.Green,
    logo: false,
  },
];

const MailSignature = () => {
  const { companyCode } = useRecoilValue(MyInfoState);
  const signatureCards = FnCoCompanyCode.includes(companyCode) ? (
    <>
      {SignatureFNCoCards.map((card, idx) => (
        <SignatureItem key={`signature-fnco-${idx}`} {...card} />
      ))}
      <SloganItem />
    </>
  ) : (
    <>
      {SignatureFNFCards.map((card, idx) => (
        <SignatureItem key={`signature-fnf-${idx}`} {...card} />
      ))}
    </>
  );

  return (
    <Layout>
      <HeaderLayout>
        <StyledTitle>메일 서명</StyledTitle>
        <StyledLink>서명 설정하러 가기</StyledLink>
      </HeaderLayout>
      <StyledContent>
        원하시는 서명을 복사해 Outlook에서 사용하세요.
      </StyledContent>
      <RowLayout>{signatureCards}</RowLayout>
    </Layout>
  );
};

export default MailSignature;
