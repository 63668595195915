import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Menu(theme: Theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#B7BBC2',
          background: 'transparent',
          borderRadius: '4px',
          padding: 0,
          marginBottom: '12px',
          '&:last-of-type': {
            marginBottom: 0,
          },
          '&:focus': {
            background: 'transparent',
          },
          '&:hover': {
            color: 'black',
          },
          '&.Mui-selected': {
            background: 'transparent',
            color: 'black',
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
          '&.Mui-disabled': {
            background: 'transparent',
          },
        },
      },
    },
  };
}
