import styled from '@emotion/styled';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  padding-left: 10px;
`;
const StyledBack = styled.button`
  padding: 1rem;
  display: flex;
  align-items: center;
  color: black;
  gap: 1rem;
  height: 65px;
  font-weight: 600;
  font-size: 1rem;
`;

StyledBack.defaultProps = {
  type: 'button',
};
const Layout = styled.div``;

export { StyledHeader, Layout, StyledBack };
