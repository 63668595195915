import { Theme } from '@mui/material/styles';
import { IconButtonProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function IconButton(theme: Theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: IconButtonProps }) => ({
          width: 32,
          height: 32,
          borderRadius: 4,
        }),
      },
    },
  };
}
