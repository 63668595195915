import { DialogTitle, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

const StyledTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const DialogCloseButton = styled(CloseIcon)`
  cursor: pointer;
  :hover {
    color: ${(theme) => theme.theme.colors.primary};
  }
`;
DialogCloseButton.defaultProps = {
  id: 'dialog-close-button',
};
const HeaderTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
`;

const HeaderContent = styled.div`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: 1rem;
`;

const StyledTextArea = styled.textarea`
  resize: none;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #e7e9ec;
  outline: none;
  transition: border-color 0.2s ease-in-out;
  padding: 1rem;
  line-height: 1.5rem;
  font-family: inherit;
  ::placeholder {
    color: ${({ theme }) => theme.colors.whiteHover};
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
`;
const StyledCloseButton = styled.button`
  width: 100%;
  height: 3rem;
  border: #e7e9ec 1px solid;
  border-radius: 0.42rem;
`;

StyledCloseButton.defaultProps = {
  type: 'button',
};

const StyledSubmitButton = styled.button`
  width: 100%;
  min-width: max-content;
  border-radius: 0.42rem;
  background-color: var(--primary-color);
  color: white;
  transition: opacity 0.2s ease-in-out;
  :disabled {
    opacity: 0.3;
  }
`;
const Container = styled.div`
  padding: 1rem;
`;

export {
  Container,
  StyledTitle,
  HeaderTitle,
  HeaderContent,
  StyledTextArea,
  StyledButtonContainer,
  StyledCloseButton,
  StyledSubmitButton,
};
