import axios from 'axios';
import * as process from 'process';

export enum ApiClientType {
  SSR,
  Client,
}

export type APIErrorHandlingOptions = {
  shouldReturnRawError?: boolean;
};

type ResponseDataType = {
  message: string;
  code: number;
};

export const processApiError = (
  error: unknown,
  options?: APIErrorHandlingOptions,
) => {
  if (options?.shouldReturnRawError) return error;

  if (axios.isAxiosError<ResponseDataType, unknown>(error)) {
    // axios에서 발생한 error
    const { response } = error;
    return error;
    // if (response?.status && response.data) {
    //   if (response.data.message) {
    //     return Error(`${response.status} - ${response.data.message}`);
    //   } else {
    //     return Error(`${response.status} - ${JSON.stringify(response.data)}`);
    //   }
    // }
  } else {
    return error;
  }

  return error;
};

export const createApiClient = () =>
  axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
  });
