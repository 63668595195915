import styled from '@emotion/styled';

const StyledTable = styled.table<{ color: string }>`
  background-color: ${({ color }) => color};
`;
const CardLayout = styled.div`
  display: flex;
  padding: 0 2rem;
`;

const StyledSpan = styled.span`
  vertical-align: middle;
  font-size: 11px;
  color: #f4ede8;
  font-weight: 400;
`;
const StyledTd = styled.td`
  padding: 22px 24.5px;
  font-size: 15.3px;
  color: #f4ede8;
  font-weight: bold;
  white-space: nowrap;
`;

const StyledOrganization = styled.div`
  font-size: 14px;
  margin-top: 2px;
  font-weight: 500;
  max-width: 336px;
  white-space: normal;
  word-break: keep-all;
`;
const StyledTd2 = styled.td`
  font-size: 13.6px;
  color: #a5bbe1;
  padding: 10px 0 0 35px;
  white-space: nowrap;
  vertical-align: bottom;
`;

const StyledP = styled.p`
  margin: 0 0 3px;
  padding: 0;
  line-height: 16px;
`;

const StyledP4 = styled.p`
  margin: 0;
  padding: 0;
  line-height: 16px;
  max-width: 195px;
  white-space: normal;
  word-break: keep-all;
`;

const StyledP2 = styled.p`
  margin: 0;
  padding: 0;
  line-height: 16px;
`;

const StyledP3 = styled.p`
  margin: 0 0 2px;
  padding: 0;
  line-height: 16px;
`;

const StyledLink = styled.a`
  color: #f4ede8;
  text-decoration: none !important;
  font-size: 12.75px;
  font-weight: 400;
  padding-top: 10px;
`;

StyledLink.defaultProps = {
  target: '_blank',
};

export {
  StyledTable,
  CardLayout,
  StyledP,
  StyledP2,
  StyledP3,
  StyledP4,
  StyledLink,
  StyledTd2,
  StyledOrganization,
  StyledSpan,
  StyledTd,
};
