import React, { CSSProperties, ReactNode } from 'react';
import { useTheme } from '@emotion/react';

type P = {
  sx?: CSSProperties;
  title: string;
  subTitle?: string;
  children: ReactNode;
};

const InputField = ({ sx, title, subTitle, children }: P) => {
  const theme = useTheme();
  return (
    <div style={sx}>
      <div>{title}</div>
      {subTitle && (
        <div style={{ color: theme.colors.textSecondary }}>{subTitle}</div>
      )}
      {children}
    </div>
  );
};
export default InputField;
