import styled from '@emotion/styled';

const Layout = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
`;

const StyledPrintButton = styled.button`
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  transition: opacity 0.2s ease-in-out;
  :disabled {
    opacity: 0.1;
  }
`;

const Input = styled.input`
  border-style: solid;
  border-color: var(--text-secondary-color);
  height: 2.5rem;
  width: 70rem;
  padding: 0.75rem;
  transition: border-color 0.1s ease-in-out;
  border-radius: 0.25rem 0 0 0.25rem;
  outline: none;
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  :read-only {
    border-color: ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.secondary};
    color: var(--text-secondary-color);
  }
`;

Input.defaultProps = {
  type: 'password',
};

export { Layout, StyledPrintButton, Input };
