import { SvgIcon, SvgIconProps } from '@mui/material';

// ----------------------------------------------------------------------

// Using for Checkbox
export function CheckboxIcon(props: SvgIconProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 21" fill="none" {...props}>
      <rect x="0.5" y="0.517578" width="19" height="19" rx="1.5" fill="white" />
      <rect
        x="0.5"
        y="0.517578"
        width="19"
        height="19"
        rx="1.5"
        stroke="#B7BBC2"
      />
    </svg>
  );
}

export function CheckboxCheckedIcon(props: SvgIconProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 21" fill="none" {...props}>
      <rect y="0.0175781" width="20" height="20" rx="2" fill="black" />
      <path
        d="M14.9746 6.24023L8.57821 13.6275L4.97461 10.0239"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function GrayArrowDown(props: SvgIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_1478_48734)">
        <path
          d="M7 10L12 15L17 10"
          stroke="#34383D"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1478_48734">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function GrayArrowUp(props: SvgIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_1478_22366)">
        <path
          d="M7 14.5L12 9.5L17 14.5"
          stroke="black"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1478_22366">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function GrayDoubleArrowLeft(props: SvgIconProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M7 14.7598L0.999999 7.75977L7 0.759766"
        stroke="#5F636B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14.7598L8 7.75977L14 0.759766"
        stroke="#5F636B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function GrayDoubleArrowRight(props: SvgIconProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M9 15.5098L15 8.50977L9 1.50977"
        stroke="#5F636B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 15.5098L7 8.50977L1 1.50977"
        stroke="#5F636B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function EditIcon(props: SvgIconProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M9.86133 15.4877L15.083 15.4877"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M15.178 7.05914L7.74315 14.494L4.55798 14.9006L4.97392 11.7248L12.4088 4.2899L15.178 7.05914Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CircleDeleteIcon(props: SvgIconProps) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
      <circle cx="8.60352" cy="8.41406" r="8" fill="#D4D7DB" />
      <path
        d="M11.0029 6.01367L6.203 10.8137"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.20312 6.01367L11.0031 10.8137"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SearchIcon(props: SvgIconProps) {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        d="M16.25 16.6641L13.2339 13.648M13.2339 13.648C14.2393 12.6427 14.8611 11.2538 14.8611 9.71965C14.8611 6.65139 12.3738 4.16406 9.30556 4.16406C6.23731 4.16406 3.75 6.65139 3.75 9.71965C3.75 12.7879 6.23731 15.2752 9.30556 15.2752C10.8397 15.2752 12.2286 14.6534 13.2339 13.648Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SelectArrow(props: SvgIconProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <g id="20 / Arrow01">
        <path
          id="Vector 266"
          d="M5.83301 8.33301L9.99967 12.4997L14.1663 8.33301"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function WhiteClose(props: SvgIconProps) {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
      <g id="shape">
        <path
          id="Vector 46"
          d="M8.75 1.25L1.25011 8.75"
          stroke="white"
          strokeWidth="0.857143"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector 47"
          d="M1.25 1.25L8.74989 8.75"
          stroke="white"
          strokeWidth="0.857143"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
