import React, { ReactNode, useState } from 'react';
import CustomDialog, {
  DialogOptions,
  HandleDialog,
} from '@/components/CustomDialog/index';

type P = {
  children: ReactNode;
};

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export type DialogContextType = {
  show: HandleDialog;
  hide: (id?: string) => void;
  hideAll: () => void;
  stack: DialogType[];
};

type DialogType = {
  id?: string;
  isOpen: boolean;
  option: DialogOptions;
};

type ResultDialogType = DialogType | null;

export const DialogContext = React.createContext<DialogContextType | null>(
  null,
);

export const CustomDialogProvider = ({ children }: P) => {
  const [dialogStacks, setDialogStacks] = useState<DialogType[]>([]);

  const alertConfirmShow = (dialogOptions?: DialogOptions) => {
    const basicOption: DialogOptions = {
      type: 'alert',
      confirmButtonTitle: '확인',
      dismissButtonTitle: '취소',
    };
    if (dialogOptions) {
      if (
        dialogOptions.type === undefined &&
        !!dialogOptions.dismissButtonTitle
      ) {
        dialogOptions.type = 'confirm';
      } else if (
        dialogOptions.type === undefined &&
        !!dialogOptions.onDismiss
      ) {
        dialogOptions.type = 'confirm';
      }
    }

    setDialogStacks((prevState) =>
      prevState.filter((item) => item.id === dialogOptions?.id).length > 0
        ? [...prevState]
        : [
            ...prevState,
            {
              id: dialogOptions?.id || uuidv4(),
              isOpen: true,
              option: { ...basicOption, ...dialogOptions },
            },
          ],
    );
  };

  const handleClose = (id?: string) => {
    if (id) {
      setDialogStacks((prevState) => {
        handleAfterClose(prevState, id);
        return prevState.filter((item) => item.id !== id);
      });
    } else {
      setDialogStacks((previousArr) => {
        handleAfterClose(previousArr, id);
        return previousArr.slice(0, -1);
      });
    }
  };

  const allClose = () => {
    setDialogStacks((prevState) => {
      prevState.forEach((item) => {
        handleAfterClose(prevState, item.id);
      });
      return [];
    });
  };

  const handleAfterClose = (stack: DialogType[], id: string | undefined) => {
    const target = dialogGet(stack, id) as ResultDialogType;
    if (target && target.option.onAfterClose) {
      target.option.onAfterClose();
    }
  };

  const dialogGet = (stack: DialogType[], id: string | undefined) => {
    if (!stack.length) {
      return null;
    }
    const result = stack.filter((item) => item.id === id);
    return (
      (result.length && result[result.length - 1]) || stack[stack.length - 1]
    );
  };

  const handleRightButtonClick = (dialog: DialogType) => {
    handleClose(dialog.id);
    dialog.option.onConfirm?.();
  };

  const handleLeftButtonClick = (dialog: DialogType) => {
    handleClose(dialog.id);
    dialog.option.onDismiss?.();
  };

  const handleHide = () => handleClose();
  const handleHideAll = () => allClose();

  return (
    <>
      <DialogContext.Provider
        value={{
          show: alertConfirmShow,
          hide: handleHide,
          hideAll: handleHideAll,
          stack: dialogStacks,
        }}
      >
        {children}
        {dialogStacks.map((dialog) => (
          <CustomDialog
            key={`dialog_${dialog.id}`}
            open={dialog.isOpen}
            onClose={handleClose}
            onClickOk={() => handleRightButtonClick(dialog)}
            onClickCancel={() => handleLeftButtonClick(dialog)}
            dialogOptions={dialog.option}
          />
        ))}
      </DialogContext.Provider>
    </>
  );
};
