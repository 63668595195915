import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { CardMedia, Dialog, DialogContent } from '@mui/material';
import { StyledTitle } from '@/components/LinkableDialog/styles';
import styled from '@emotion/styled';
import {
  DialogCloseButton,
  DialogIconContainer,
  DialogBackButton,
} from '@/styles/styles';
import { useRouter } from 'next/router';
import { RevalidateApi, revalidateApiList } from '@/components/LinkableDialog';
import { useSWRConfig } from 'swr';
import { setJwtToken } from '@/utils/tokens';

const StyledButton = styled.span`
  color: var(--text-primary-color);
  font-weight: 600;
  display: flex;
  cursor: pointer;
  align-items: center;
  .MuiSvgIcon-root {
    color: var(--icon-color);
  }
`;

type P = {
  iframeId: string;
  trigger: ReactNode;
  dialogTitle: ReactNode;
  url: string;
  style?: CSSProperties;
  onClose?: () => void;
  revalidateTarget?: RevalidateApi;
  isMenuLayer?: boolean;
  backButton?: boolean;
};

const FullPageIframeModal = ({
  iframeId,
  trigger,
  dialogTitle,
  url,
  style,
  onClose,
  revalidateTarget,
  isMenuLayer,
  backButton = true,
}: P) => {
  const { mutate } = useSWRConfig();
  const router = useRouter();

  const iframeFlag = iframeId
    ? `/#${iframeId.replaceAll('/', '_')}_open`
    : null;

  const routerParsed = decodeURIComponent(router.asPath).replace(
    '/[/s|\\/]/g',
    '_',
  );
  const isOpen = `/${routerParsed.slice(1)}` === iframeFlag;
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    if (url.includes('pop.fnf.co.kr/sales')) {
      alert('Sales 데이터는 2024년 5월 1일부터 밀키웨이에서 제공됩니다.');
    }
    if (iframeFlag) router.push(iframeFlag);
    else history.pushState({}, '', iframeFlag);
  };

  useEffect(() => {
    if (iframeId) setOpen(isOpen);
  }, [isOpen, router.asPath]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
    if (revalidateTarget) {
      revalidateApiList
        .get(revalidateTarget)
        ?.forEach((apiUrl) => mutate(apiUrl, undefined, { revalidate: true }));
    }
    router.replace('/');
  };

  const handleBack = () => {
    if (isMenuLayer) router.replace('/');
    else router.back();
  };

  return (
    <>
      <StyledButton style={style} onClick={handleOpen}>
        {trigger}
      </StyledButton>
      <Dialog open={open} onClose={handleClose} fullScreen>
        <StyledTitle>
          {dialogTitle}
          <DialogIconContainer>
            {backButton && <DialogBackButton onClick={handleBack} />}
            <DialogCloseButton onClick={handleClose} />
          </DialogIconContainer>
        </StyledTitle>
        <DialogContent style={{ padding: '0' }} dividers>
          <CardMedia
            id={'full-page-iframe'}
            style={{
              height: '100%',
            }}
            component={'iframe'}
            image={setJwtToken(url)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FullPageIframeModal;
