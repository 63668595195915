import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 3rem;
`;

const StyledTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
`;

const StyledContent = styled.div`
  display: flex;
  margin-top: 1rem;
  padding: 1rem 2rem;
  color: ${({ theme }) => theme.colors.primary};
  background-color: rgba(225, 240, 255, 1);
  border-radius: 0.42rem;
`;

const CustomDivider = styled.div`
  border-top: 1px dashed var(--text-secondary-color);
`;

const StyledDataTitle = styled.div`
  display: flex;
  width: 50%;
`;

const StyledData = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
`;

const RowLayout = styled.div``;
const StyledSubTitle = styled.div`
  font-size: 1rem;
  margin-top: 1rem;
  > span {
    color: red;
  }
`;
const Input = styled.input`
  border-style: solid;
  border-color: var(--text-secondary-color);
  height: 2.5rem;
  width: 70%;
  padding: 0.75rem;
  transition: border-color 0.1s ease-in-out;
  border-radius: 0.4rem;
  outline: none;
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  :read-only {
    border-color: ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.secondary};
    color: var(--text-secondary-color);
  }
`;

Input.defaultProps = {
  type: 'password',
};

const StyledSubmitButton = styled.button`
  width: 100%;
  height: 2.5rem;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  transition: opacity 0.2s ease-in-out;
  :disabled {
    opacity: 0.1;
  }
`;

const StyledLinkButton = styled.a`
  margin-top: 0.5rem;
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  transition: opacity 0.2s ease-in-out;
`;

StyledLinkButton.defaultProps = {
  href: 'https://account.activedirectory.windowsazure.com/ChangePassword.aspx',
  target: '_blank',
};

export {
  RowLayout,
  Container,
  StyledData,
  StyledDataTitle,
  CustomDivider,
  StyledContent,
  StyledTitle,
  Input,
  StyledSubmitButton,
  StyledLinkButton,
  StyledSubTitle,
};
