import { ReactNode } from 'react';
import { StyledLayout } from '@/components/Layout/styles';
import Header from '@/components/Header';
import { useRouter } from 'next/router';

type P = {
  children: ReactNode;
};
const Layout = ({ children }: P) => {
  const router = useRouter();
  const v2Version =
    router.pathname.startsWith('/organization') ||
    router.pathname.startsWith('/approval') ||
    router.pathname.startsWith('/m');

  return (
    <StyledLayout v2Version={v2Version}>
      {!v2Version && <Header />}
      {children}
    </StyledLayout>
  );
};

export default Layout;
