import styled from '@emotion/styled';

const StyledLayout = styled.div`
  padding: 2rem;
`;

const Container = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
`;

export { StyledLayout, Container, StyledTitle };
