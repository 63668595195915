import {
  Layout,
  StyledContent,
  StyledTitle,
  StyledSubTitle,
} from '@/components/Profile/SecondItem/ShopId/styles';

import AuthInfo from 'components/Profile/SecondItem/ShopId/AuthInfo';
import AuthShopId from 'components/Profile/SecondItem/ShopId/AuthShopId';

const ShopId = () => {
  return (
    <Layout>
      <StyledTitle>자사몰 직원할인 인증</StyledTitle>
      <StyledSubTitle>
        Discovery, MLB, 바닐라코 임직원 할인을 위한 ID 인증화면입니다.
      </StyledSubTitle>
      <StyledContent style={{ color: '#19c5be', backgroundColor: '#c9f7f4' }}>
        자사 온라인 쇼핑몰에서 가입한 계정을 인증해주세요. 등록된 계정을
        변경하려는 경우, 재인증 시 새로운 인증 정보로 적용됩니다.
      </StyledContent>
      <AuthShopId />
      <AuthInfo />
    </Layout>
  );
};

export default ShopId;
