import styled from '@emotion/styled';

const StyledTable = styled.div`
  padding-top: 1rem;
  width: 100%;
  table {
    width: 100%;
    border: 1px solid #e9edf3;
    background-color: #f4f6f9;
    color: #3e4253;
    border-collapse: collapse;
    th,
    td {
      border: 1px solid #e9edf3;
      height: 40px;
    }
  }
  .noData {
    text-align: center;
  }
  tbody {
    background-color: white;
    th,
    td {
      border: 1px solid #e9edf3;
      height: 35px;
      text-align: center;
    }
  }
`;

export { StyledTable };
