import {
  RowLayout,
  Container,
  StyledData,
  StyledDataTitle,
  CustomDivider,
  StyledContent,
  StyledTitle,
  Input,
  StyledSubmitButton,
  StyledLinkButton,
  StyledSubTitle,
} from '@/components/Profile/SecondItem/PassWord/styles';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useChangePassWord } from '@/hooks/apiHooks';

export type Password = {
  currentPassword: string;
  updatePassword: string;
  updateConfirmPassword: string;
};

const PassWord = () => {
  const { register, handleSubmit, formState, reset } = useForm<Password>();

  const onSubmit: SubmitHandler<Password> = (data) => {
    useChangePassWord(data)
      .then(() => {
        reset();
        alert('비밀번호가 변경되었습니다.');
      })
      .catch((e) =>
        alert(e?.response?.data?.message ?? '잠시후 다시 시도해 주세요'),
      );
  };

  const pwdInfo = (
    <div>
      아래 회사 내부 시스템에 공통 적용됩니다.
      <ul>
        <li>MAIN ERP(인사)</li>
        <li>MERP</li>
        <li>PDM</li>
      </ul>
    </div>
  );

  const msPwdInfo = (
    <div>
      MS O365 비밀번호
      <ul>
        <li>MS O365</li>
        <li>Intranet</li>
        <li>Finstagram Web</li>
        <li>SA (Simply Accounting)</li>
        <li>PLM</li>
        <li>SMS</li>
        <li>SERP</li>
      </ul>
    </div>
  );

  return (
    <Container>
      <RowLayout>
        <StyledTitle>비밀번호 관리</StyledTitle>
        <StyledSubTitle>
          비밀번호는 <span>8~16자, 영문, 숫자, 특수문자를 조합</span>해 사용해야
          합니다.
        </StyledSubTitle>
        <StyledContent style={{ color: '#19c5be', backgroundColor: '#c9f7f4' }}>
          {pwdInfo}
        </StyledContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledData>
            <StyledDataTitle>기존 비밀번호</StyledDataTitle>
            <Input {...register('currentPassword', { required: true })} />
          </StyledData>
          <CustomDivider />
          <StyledData>
            <StyledDataTitle>새 비밀번호</StyledDataTitle>
            <Input {...register('updatePassword', { required: true })} />
          </StyledData>
          <CustomDivider />
          <StyledData>
            <StyledDataTitle>새 비밀번호 확인</StyledDataTitle>
            <Input {...register('updateConfirmPassword', { required: true })} />
          </StyledData>
          <StyledSubmitButton disabled={!formState.isValid}>
            비밀번호 변경
          </StyledSubmitButton>
        </form>
      </RowLayout>
      <RowLayout>
        <StyledTitle>MS O365 비밀번호</StyledTitle>
        <StyledContent>{msPwdInfo}</StyledContent>
        <StyledSubTitle>
          MS O365 비밀번호 변경은 아래에서 직접 변경 가능합니다.
        </StyledSubTitle>
        <StyledLinkButton>링크이동</StyledLinkButton>
      </RowLayout>
    </Container>
  );
};

export default PassWord;
