import styled from '@emotion/styled';
import { Input } from '@mui/material';
const StyledInputLayout = styled.div<{ open: boolean }>`
  background-color: white;
  position: absolute;
  width: 100%;
  opacity: ${({ open }) => (open ? '1' : '0')};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  transition: visibility 0.3s linear, opacity 0.3s linear;
  #submit-button {
    position: absolute;
  }
`;
const StyledButton = styled.button`
  transition: color 0.2s ease-in-out;
  color: ${({ theme }) => theme.colors.gray};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;
  padding-bottom: 5rem;
`;
const StyledTitle = styled.div`
  padding: 2rem;
  font-size: 1.25rem;
  font-weight: 600;
`;

const StyledContent = styled.div`
  display: flex;
  margin: 0 2rem 3rem 2rem;
  padding: 3rem 1rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  background-color: rgba(225, 240, 255, 1);
  height: 2rem;
  border-radius: 0.42rem;
`;

const CustomDivider = styled.div`
  width: 100%;
  height: 1px;
  margin: 1rem 0;

  border-top: 1px dashed var(--text-secondary-color);
  border-bottom: none;
  :last-child {
    display: none;
  }
`;

const StyledDataTitle = styled.div`
  display: flex;
  width: 30%;
`;
const StyledDataContent = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  width: 70%;
`;

const StyledData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem;
`;
const RowLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  gap: 0.5rem;
`;
const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
`;

const StyledInput = styled(Input)`
  width: 80%;
  border-radius: 0.42rem;
  padding: 0 1rem;
  border: 1px solid #e4e6ef;
`;

const StyledIconButton = styled.button`
  padding: 0;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.6;
  }
`;
StyledIconButton.defaultProps = {
  type: 'button',
};
export {
  RowLayout,
  Layout,
  StyledData,
  StyledDataContent,
  StyledDataTitle,
  CustomDivider,
  StyledContent,
  StyledTitle,
  StyledInputLayout,
  StyledButton,
  StyledForm,
  StyledInput,
  StyledIconButton,
};
