import { MenuData } from '@/types/api';

export enum ViewType {
  None,
  Modal,
  Blank,
}
export enum SiteHeader {
  OurSites = 'OurSites',
  ForWork = 'ForWork',
  BugReport = 'BugReport',
  FnfNews = 'FnfNews',
  Approval = 'Approval',
}

export type Site = {
  title: string;
  node: Site[];
  url?: string;
  viewType: ViewType;
  upcoming?: boolean;
};

export const ourSitesLoadMaps: Site[] = [
  {
    title: 'Our Sites',
    viewType: ViewType.None,
    node: [
      {
        title: 'F&F',
        url: 'https://fnf.co.kr/',
        viewType: ViewType.Blank,
        node: [],
      },
      {
        url: 'http://www.collected.co.kr',
        viewType: ViewType.Blank,
        title: 'COLLECTED',
        node: [],
      },
      {
        url: 'http://www.jardinperdu.com/',
        viewType: ViewType.Blank,
        title: 'JARDIN PERDU',
        node: [],
      },
      {
        url: 'https://www.mlb-korea.com/',
        viewType: ViewType.Blank,
        title: 'MLB',
        node: [],
      },
      {
        url: 'https://www.discovery-expedition.com/',
        viewType: ViewType.Blank,
        title: 'DISCOVERY-EXPEDITION',
        node: [],
      },
      {
        url: 'https://www.stretch-angels.com',
        viewType: ViewType.Blank,
        title: 'STRETCH ANGELS',
        node: [],
      },
      {
        url: 'https://www.duvetica.co.kr/',
        viewType: ViewType.Blank,
        title: 'DUVETICA',
        node: [],
      },
      {
        url: 'https://supra-korea.com/',
        viewType: ViewType.Blank,
        title: 'SUPRA',
        node: [],
      },
      {
        url: 'https://www.sergiotacchini.co.kr/',
        viewType: ViewType.Blank,
        title: 'SERGIO TACCHINI',
        node: [],
      },
      {
        url: 'https://www.banila.com/',
        viewType: ViewType.Blank,
        title: 'BANILA CO',
        node: [],
      },
    ],
  },
];

export const forWorkLoadMaps: Site[] = [
  {
    title: 'For Work',
    viewType: ViewType.None,
    node: [
      {
        title: 'Company',
        viewType: ViewType.Modal,
        url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/intranet/company`,
        node: [],
      },
      {
        title: 'SMS',
        viewType: ViewType.Blank,
        url: 'https://sms.fnf.co.kr/',
        node: [],
      },
      {
        title: 'SCM',
        viewType: ViewType.Blank,
        url: 'https://scm.fnf.co.kr/',
        node: [],
      },
      {
        title: 'MERP',
        viewType: ViewType.Blank,
        url: 'https://erp.fnf.co.kr/erp.do/',
        node: [],
      },
      {
        title: '매장 재고조사/정산',
        viewType: ViewType.Blank,
        url: 'https://inspect.fnf.co.kr/',
        node: [],
      },
    ],
  },
];

export const bugReportLoadMaps: MenuData[] = [
  {
    name: '오류 불편 신고',
    link: 'https://fnf.atlassian.net/servicedesk/customer/portal/50/group/154/create/813',
    action: 'newTab',
    isDisplay: true,
    childData: [],
  },
];

export const newLoadMaps: Site[] = [
  {
    title: 'F&F News',
    url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/intranet/ff_news`,
    viewType: ViewType.Modal,
    node: [],
  },
  {
    title: 'Digital',
    url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/intranet/digital`,
    viewType: ViewType.Modal,
    node: [],
  },
  {
    title: 'COMM',
    viewType: ViewType.None,
    node: [
      {
        title: '그룹사 공지사항',
        url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=2000&c=H`,
        viewType: ViewType.Modal,
        node: [],
      },
      {
        title: 'F&F 공지사항',
        url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=2050&c=FN`,
        viewType: ViewType.Modal,
        node: [],
      },
      {
        title: '경조사',
        url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=2000&c=C`,
        viewType: ViewType.Modal,
        node: [],
      },
      {
        title: 'Press Release',
        url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=3000`,
        viewType: ViewType.Modal,
        node: [
          {
            title: 'F&F',
            url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=3000&c=FF`,
            viewType: ViewType.Modal,
            node: [],
          },
          {
            title: 'MLB',
            url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=3000&c=M`,
            viewType: ViewType.Modal,
            node: [],
          },
          {
            title: 'MLB Kids',
            url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=3000&c=I`,
            viewType: ViewType.Modal,
            node: [],
          },
          {
            title: 'Discovery',
            url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=3000&c=X`,
            viewType: ViewType.Modal,
            node: [],
          },
          {
            title: 'BANILA CO',
            url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=3000&c=L`,
            viewType: ViewType.Modal,
            node: [],
          },
          {
            title: 'B. by BANILA',
            url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=3000&c=LC`,
            viewType: ViewType.Modal,
            node: [],
          },
          {
            title: 'lala+Lab',
            url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=3000&c=LL`,
            viewType: ViewType.Modal,
            node: [],
          },
          {
            title: 'Curly Studio',
            url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=3000&c=LS`,
            viewType: ViewType.Modal,
            node: [],
          },
          {
            title: 'Stretch Angels',
            url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=3000&c=A`,
            viewType: ViewType.Modal,
            node: [],
          },
          {
            title: 'KU:S',
            url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=3000&c=KU`,
            viewType: ViewType.Modal,
            node: [],
          },
        ],
      },
      {
        title: 'Sharing',
        url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/intranet/sharing`,
        viewType: ViewType.Modal,
        node: [],
      },
      {
        title: 'CEO Room',
        url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/bbs/L?n=2110&c=C`,
        viewType: ViewType.Modal,
        node: [],
      },
    ],
  },
];

export const approvalLoadMaps: Site[] = [
  {
    title: 'Approval',
    viewType: ViewType.Modal,
    url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/approval/search`,
    node: [],
  },
  {
    title: 'Sales',
    viewType: ViewType.Modal,
    url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/sales/sales_view`,
    node: [],
  },
  {
    title: 'Library',
    viewType: ViewType.Modal,
    url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/books`,
    node: [],
  },
  {
    title: 'Trend & issue',
    viewType: ViewType.Modal,
    url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/intranet/ti`,
    node: [],
  },
  {
    title: 'Etc & News',
    viewType: ViewType.None,
    node: [
      {
        title: '인사정보',
        viewType: ViewType.None,
        node: [
          {
            title: '인사 조회',
            viewType: ViewType.Modal,
            url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/person`,
            node: [],
          },
          {
            title: '조직도(법인별)',
            viewType: ViewType.Modal,
            url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/person/org_diagram`,
            node: [],
          },
        ],
      },
      {
        title: '직원구매내역',
        viewType: ViewType.Modal,
        url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/person/emp_view`,
        node: [],
      },
      {
        title: '업무 신청 (총무팀)',
        viewType: ViewType.Modal,
        url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/intranet/ga_teams`,
        node: [],
      },
      {
        title: '콘도 예약',
        viewType: ViewType.Modal,
        url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/condo`,
        node: [],
      },
    ],
  },
];
