import {
  StyledSubContent,
  StyledRowLayout,
  StyledContentLayout,
  StyledContent,
  StyledReadButton,
} from '@/components/Alarm/NotificationHistoryItem/styles';
import { NotiHistoryData } from '@/types/api';
import { useMessageRead } from '@/hooks/apiHooks';

type P = { mobileMode?: boolean; handleReadClick(id: number): void };
const NotificationHistoryItem = ({
  title,
  body,
  watched,
  createdAt,
  templateKey,
  link,
  data,
  id,
  mobileMode = false,
  handleReadClick,
}: NotiHistoryData & P) => {
  const read = watched === 1;
  const popToi = link.replace('//pop.', '//i.');

  const handleClick = () => {
    if (window?.isRNWebview && data?.mobileActionType) {
      const modifyLink = link.replace('//i.', '//pop.');
      const sendData = JSON.stringify({
        actionType: data.mobileActionType,
        tabName: data.tabName,
        url: modifyLink,
      });
      window?.ReactNativeWebView?.postMessage(sendData);
    } else {
      window.open(popToi, '_black', 'noopener, noreferrer');
    }
    readMessage();
  };

  const readMessage = () => {
    useMessageRead(id.toString()).then(() => {
      handleReadClick(id);
    });
  };

  const handleRead = (e: React.MouseEvent<HTMLButtonElement>) => {
    readMessage();
    e?.stopPropagation();
  };

  return (
    <StyledRowLayout {...{ read, mobileMode }} onClick={handleClick}>
      <StyledContentLayout>
        <div style={{ fontWeight: '700' }}>{title}</div>
        <StyledContent>{body}</StyledContent>
        <StyledSubContent>
          <div>{createdAt}</div>
          <StyledReadButton onClick={(e) => handleRead(e)}>
            Mark as read
          </StyledReadButton>
        </StyledSubContent>
      </StyledContentLayout>
    </StyledRowLayout>
  );
};

export default NotificationHistoryItem;
