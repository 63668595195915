import {
  Layout,
  Input,
  StyledCertifyButton,
} from '@/components/Profile/SecondItem/ProofEmp/CertifySMS/styles';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CertifyForm, useCertifySmsCode } from '@/hooks/apiHooks';
import { useState } from 'react';

import PrintEmpProof from '@/components/Profile/SecondItem/ProofEmp/PrintEmpProof';

type P = {
  smsVerificationId: string;
  handleInit: () => void;
};

const CertifySMS = ({ smsVerificationId, handleInit }: P) => {
  const { register, handleSubmit } = useForm<CertifyForm>();
  const [certifyData, setCertifyData] = useState<CertifyForm>();

  const authorized = !!certifyData;

  const onSubmit: SubmitHandler<CertifyForm> = (data) => {
    const tempCertifyData: CertifyForm = {
      smsVerificationId: smsVerificationId,
      smsVerificationCode: data.smsVerificationCode,
    };
    useCertifySmsCode(tempCertifyData)
      .then((_) => setCertifyData(tempCertifyData))
      .catch((e) => alert(e));
  };

  const handleCertifyData = () => {
    setCertifyData(undefined);
    handleInit();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Layout>
          <Input
            placeholder={'인증번호'}
            {...register('smsVerificationCode')}
            disabled={authorized}
          />
          <StyledCertifyButton disabled={authorized}>인증</StyledCertifyButton>
        </Layout>
      </form>
      {authorized && (
        <PrintEmpProof
          certifyData={certifyData}
          handleInit={handleCertifyData}
        />
      )}
    </>
  );
};

export default CertifySMS;
