import styled from '@emotion/styled';

const Layout = styled.div`
  display: flex;
  margin-top: 1.5rem;
  :first-child {
    margin-top: 0;
  }
`;

const StyledButton = styled.div`
  color: var(--text-primary-color);
  display: flex;
  cursor: pointer;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  margin-top: 0rem;
  font-size: small;
  transition: color 0.1s ease-in-out;
  word-break: keep-all;
  font-family: var(--font-Pretendard);
  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  .MuiSvgIcon-root {
    padding: 0.05rem;
    color: var(--icon-color-light);
  }
`;

const StyledTitle = styled.div<{ watched: 0 | 1 }>`
  font-weight: ${({ watched }) =>
    watched ? 400 : 700}; // TODO : 클릭 시 볼드 해제되어야 함
`;

export { Layout, StyledButton, StyledTitle };
