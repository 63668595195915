import { createApiClient } from '@/utils/api/ApiDefault';
import { AzureRefreshToken } from '@/types/api';
import { getCookie, setCookie } from '@/utils/tokens';
import { MS_ACCESS_TOKEN, MS_REFRESH_TOKEN, twoWeek } from '@/const/tokens';
import axios from 'axios';
import { emptyFunction, sendSentry } from '@/utils/function';

const axiosInstance = createApiClient();

axiosInstance.interceptors.request.use(async (config) => {
  // NOTE: 요청 전달되기 전 작업 처리
  const extraAuthorization = config.headers.Authorization;

  if (extraAuthorization) {
    if (process.env.NODE_ENV === 'development')
      console.log(`👉 Extra Auth 존재`);
  } else {
    config.headers.Authorization = `Bearer ${getCookie(MS_ACCESS_TOKEN)}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  // NOTE: 응답 200번대 status일 때 응답 성공 직전 호출
  // NOTE: 이 작업 이후 .then()으로 이어진다
  (response) => response,
  // NOTE: 응답 200번대가 아닌 status일 때 응답 에러 직전 호출
  // NOTE: 이 작업 이후 .catch()로 이어진다
  async (err) => {
    const originalRequest = err.config;

    const status = err?.response?.status;
    const code = err?.response?.data?.code;

    if (status === 401 || (code === '2002' && !originalRequest._retry)) {
      const refreshToken = getCookie(MS_REFRESH_TOKEN);

      if (!refreshToken || refreshToken === 'undefined') {
        window.location.href = '/login';

        // Promise chaining 끊기
        return new Promise(emptyFunction);
      }

      originalRequest._retry = true;

      const token = await axios
        .get<AzureRefreshToken>(
          `${process.env.NEXT_PUBLIC_API_URL}/api/auth/azure/token/refresh`,
          {
            headers: {
              authorization: `Bearer ${refreshToken}`,
            },
          },
        )
        .then((res) => res.data.data);

      const { access_token, expires_at, refresh_token } = token;

      setCookie(MS_ACCESS_TOKEN, access_token, expires_at);
      setCookie(MS_REFRESH_TOKEN, refresh_token, twoWeek);

      originalRequest.headers.authorization = `Bearer ${access_token}`;

      return axios(originalRequest);
    }
    if (status < 400 || status >= 500) {
      sendSentry(err, 'MS');
    }
    return Promise.reject(err);
  },
);

export { axiosInstance as msInstance };
