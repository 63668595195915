import { CSSProperties, ReactNode } from 'react';

type P = {
  title: string;
  content: ReactNode;
  style?: CSSProperties;
  children?: ReactNode;
};
const InfoItem = ({ title, content, style, children }: P) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        marginTop: '28px',
        ...style,
      }}
    >
      <div style={{ color: '#888D96' }}>{title}</div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: '16px', fontWeight: '500' }}>
          {content ? content : '-'}
        </div>
        {children}
      </div>
    </div>
  );
};
export default InfoItem;
