import React, { CSSProperties } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  StyledTextArea,
  StyledCount,
  StyledError,
} from '@/components/CustomTextInput/styles';

type P = {
  placeHolder: string;
  rows: number;
  value: string;
  maxLength: number;
  sx?: CSSProperties;
};

const CustomTextInput = ({ placeHolder, rows, value, maxLength, sx }: P) => {
  const { register, watch } = useFormContext(); // retrieve all hook methods

  const overWord = watch(value) && watch(value).length > maxLength;

  return (
    <div style={sx}>
      <StyledTextArea
        {...register(value, { maxLength: maxLength })}
        rows={rows}
        placeholder={placeHolder}
        overWord={overWord}
      />
      <StyledError overWord={overWord}>
        {overWord ? `최대 ${maxLength}자까지 입력 가능합니다.` : `\b`}
        <StyledCount overWord={overWord}>
          {(watch(value) ? watch(value).length : 0) + `/${maxLength}`}
        </StyledCount>
      </StyledError>
    </div>
  );
};

export default CustomTextInput;
