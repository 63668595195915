import { IconButton, Popover } from '@mui/material';
import {
  StyledHeader,
  StyledTitle,
  StyledButton,
  ContentWrapper,
  Layout,
  StyledNodata,
  IconLayout,
} from '@/components/Alarm/styles';
import {
  useMessageAllRead,
  useNotiHistory,
  useNotiHistoryPagination,
} from '@/hooks/apiHooks';
import NotificationHistoryItem from '@/components/Alarm/NotificationHistoryItem';

import { useState } from 'react';
import { faBell } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { FnCoCompanyCode } from '@/utils/const';

export const AlarmSuspense = () => {
  return (
    <IconButton type={'button'}>
      <FontAwesomeIcon
        icon={faBell}
        style={{
          fontSize: '1.5rem',
          color: 'white',
        }}
        shake={false}
      />
    </IconButton>
  );
};

export const CURRENT_MESSAGE = 'currentMessage';

const Alarm = () => {
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const { data } = useNotiHistory();

  const {
    data: paginationData,
    size,
    setSize,
    mutate,
  } = useNotiHistoryPagination();

  const seenMessage = localStorage.getItem(CURRENT_MESSAGE) || undefined;
  const currentMessage = data?.data.at(0)?.id.toString();

  const newMessage = currentMessage !== seenMessage;

  const noMoreData = (data?.meta.endPage || 0) <= size;
  const { companyCode } = useRecoilValue(MyInfoState);
  const isFnCo = FnCoCompanyCode.includes(companyCode);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    localStorage.setItem(CURRENT_MESSAGE, currentMessage || '');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAllRead = () => {
    useMessageAllRead().then(() => mutate());
  };

  const handleRead = (id: number) => {
    const beforeData = paginationData;
    const afterData = beforeData?.map((res) =>
      res.map((item) => (item.id === id ? { ...item, watched: 1 } : item)),
    );
    mutate(afterData);
  };

  return router.isReady ? (
    <>
      <IconLayout
        newMessage={!!newMessage}
        isFnco={isFnCo}
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={faBell} shake={!!newMessage} />
      </IconLayout>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Layout>
          <StyledHeader>
            <StyledTitle>NOTIFICATIONS</StyledTitle>
            <StyledButton onClick={handleAllRead}>
              Mark all as read
            </StyledButton>
          </StyledHeader>
          <ContentWrapper>
            {paginationData && paginationData.flat().length > 0 ? (
              paginationData
                .flat()
                .map((res, idx) => (
                  <NotificationHistoryItem
                    handleReadClick={handleRead}
                    key={`notification-${idx}`}
                    {...res}
                  />
                ))
            ) : (
              <StyledNodata>알람이 없습니다.</StyledNodata>
            )}
            {!noMoreData && (
              <button
                type={'button'}
                onClick={() => setSize((_size) => _size + 1)}
                style={{ padding: '1rem 0' }}
              >
                Load More
              </button>
            )}
          </ContentWrapper>
        </Layout>
      </Popover>
    </>
  ) : (
    <AlarmSuspense />
  );
};

export default Alarm;
