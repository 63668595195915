import styled from '@emotion/styled';

const StyledTextArea = styled.textarea<{ overWord: boolean }>`
  resize: none;
  width: 100%;
  border-radius: 0.5rem;
  border-color: ${({ overWord, theme }) =>
    overWord ? theme.colors.danger : '#e7e9ec'};
  outline: none;
  transition: border-color 0.2s ease-in-out;
  padding: 1rem;
  line-height: 1.5rem;
  font-family: inherit;
  ::placeholder {
    color: ${({ theme }) => theme.colors.whiteHover};
  }
`;

const StyledError = styled.div<{ overWord: boolean }>`
  color: ${({ overWord, theme }) =>
    overWord ? theme.colors.danger : theme.colors.textSecondary};
  font-size: 12px;
  display: flex;
  position: relative;
  margin: 0 1rem;
`;

const StyledCount = styled.div<{ overWord: boolean }>`
  color: ${({ overWord, theme }) =>
    overWord ? theme.colors.danger : theme.colors.textSecondary};
  transition: color 0.2s ease-in-out;
  position: absolute;
  right: 0;
`;

export { StyledTextArea, StyledCount, StyledError };
