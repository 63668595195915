export const copyToClipboard = (start: number, end: number, id: string) => {
  //TODO : NEED SOMETHING NEW LOGIC ?!?
  const range = new Range();

  const temp = document.getElementById(id);

  if (temp) {
    range.setStart(temp, start);
    range.setEnd(temp, end);

    document.getSelection()?.removeAllRanges();
    document.getSelection()?.addRange(range);

    document.execCommand('copy');
    document.getSelection()?.removeAllRanges();
    alert('클립보드에 복사되었습니다. 원하는 곳에 붙여넣기 하여 사용해주세요.');
  }
};
