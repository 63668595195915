import styled from '@emotion/styled';

const TopLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2rem;
  > h5 {
    font-size: 1rem;
    font-weight: bolder;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
`;

StyledButton.defaultProps = {
  type: 'button',
};

export { TopLayout, StyledButton };
