import { useMyNotiList } from '@/hooks/apiHooks';
import Accordion from '@/components/Profile/SecondItem/AlarmSettings/Accordion';
import {
  Container,
  StyledLayout,
  StyledTitle,
} from '@/components/Profile/SecondItem/AlarmSettings/styles';

const AlarmSettings = () => {
  const { data } = useMyNotiList();

  return (
    <StyledLayout>
      <StyledTitle>알림 설정</StyledTitle>
      <Container>
        {data?.map((res) => (
          <Accordion key={res.templateKey} {...res} />
        ))}
      </Container>
    </StyledLayout>
  );
};

export default AlarmSettings;
