import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  StyledInput,
  StyledButton,
  Container,
  Layout,
  StyleEmpty,
  ButtonLayout,
  StyledIconButton,
} from '@/components/CustomSelectInput/styles';
import { useDebounce } from 'usehooks-ts';
import { useGetJobList } from '@/hooks/apiHooks';
import {
  faXmark,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type P = {
  placeHolder?: string;
  value: string;
  defaultValue: string;
};

const CustomSelectInput = ({ placeHolder, value, defaultValue }: P) => {
  const { register, watch, setValue, getValues, resetField } = useFormContext(); // retrieve all hook methods

  const [focus, setFocus] = useState(false);

  const [keyword, setKeyword] = useState(defaultValue);

  const debouncedValue = useDebounce(keyword, 500);

  const { data } = useGetJobList(debouncedValue);

  return (
    <>
      <StyledInput
        {...register(value, { required: true })}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        onFocus={() => setFocus(true)}
        placeholder={placeHolder}
        onBlur={() => setFocus(false)}
        disabled={!!watch(value)}
      />
      <Container onBlur={() => setFocus(false)}>
        <ButtonLayout>
          {getValues(value) ? (
            <StyledIconButton
              onClick={() => {
                setKeyword('');
                setValue(value, '', { shouldValidate: true });
              }}
            >
              <FontAwesomeIcon icon={faXmark} size="lg" />
            </StyledIconButton>
          ) : (
            <StyledIconButton
              onClick={() => {
                setFocus(!focus);
              }}
            >
              <FontAwesomeIcon
                icon={focus ? faChevronUp : faChevronDown}
                size="lg"
              />
            </StyledIconButton>
          )}
        </ButtonLayout>
        <Layout focus={focus}>
          {data ? (
            data.meta.totalCount > 0 ? (
              data.data.map((res) => (
                <StyledButton
                  key={res.name_kor}
                  type={'button'}
                  onMouseDown={() => {
                    setValue('job', res.name_kor, { shouldValidate: true });
                    setKeyword(res.name_kor);
                    setFocus(false);
                  }}
                >
                  {res.name_kor}
                </StyledButton>
              ))
            ) : (
              <StyleEmpty>조회된 직무가 없습니다.</StyleEmpty>
            )
          ) : null}
        </Layout>
      </Container>
    </>
  );
};

export default CustomSelectInput;
