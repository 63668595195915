import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

const SeoHeader = () => {
  const router = useRouter();
  return (
    <Head>
      <title>F&F Portal</title>
      <meta property="og:site_name" content="F&F Portal" />
      <meta property="og:title" content="F&F Portal" />
      <meta name="twitter:title" content="F&F Portal" />
      <meta property="og:description" content="F&F 그룹사 전용 Portal입니다." />
      <meta
        name="twitter:description"
        content="F&F 그룹사 전용 Portal입니다."
      />
      <meta property="og:url" content="https://new-portal.fnf.co.kr/" />
      <meta property="og:image" content="/og_tag.jpg" />
      {/*FIXME: Delete this code after RN set meta version*/}
      {router.pathname === '/m' && <meta name="viewport" />}
    </Head>
  );
};

export default SeoHeader;
