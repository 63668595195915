import { Dialog, DialogContent } from '@mui/material';

import { DialogCloseButton } from '@/styles/styles';
import React, { useEffect } from 'react';
import {
  HeaderContent,
  HeaderTitle,
  StyledTitle,
  StyledButtonContainer,
  StyledCloseButton,
  StyledSubmitButton,
  Container,
} from '@/components/Profile/IntroduceJob/JobDialog/styles';
import { FormProvider, useForm } from 'react-hook-form';
import { MyJob, Passport } from '@/types/api';
import { useChangedJob } from '@/hooks/apiHooks';
import CustomTextInput from '@/components/CustomTextInput';
import InputField from '@/components/Profile/IntroduceJob/JobDialog/InputField';
import CustomSelectInput from '@/components/CustomSelectInput';

type P = {
  open: boolean;
  handleClose: () => void;
  defaultValues?: Passport;
  onSendApi: VoidFunction;
};

const JobDialog = ({ open, handleClose, defaultValues, onSendApi }: P) => {
  const method = useForm<MyJob>({
    defaultValues: {
      job: defaultValues?.job,
      summary: defaultValues?.jobDescription.summary,
      content: defaultValues?.jobDescription.content,
    },
  });

  const onSubmit = (data: MyJob) => {
    useChangedJob(data).then(() => {
      onSendApi();
      handleClose();
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      open={open}
      onClose={handleClose}
      style={{ zIndex: 1400, fontFamily: 'var(--font-Pretendard)' }}
    >
      <Container>
        <StyledTitle>
          <div>
            <HeaderTitle>업무소개</HeaderTitle>
            <HeaderContent>
              동료 임직원들에게 보여줄 수 있도록 내가 어떤 일을 하는지
              알려주세요.
              <br />
              등록된 소개는 프로필 카드에서 확인할 수 있으며, 인물검색에서도
              검색 가능합니다.
            </HeaderContent>
          </div>
          <DialogCloseButton onClick={handleClose} />
        </StyledTitle>
        <DialogContent>
          <FormProvider {...method}>
            <form onSubmit={method.handleSubmit(onSubmit)}>
              <InputField title={'직무'} sx={{ marginTop: '0.5rem' }}>
                <CustomSelectInput
                  value={'job'}
                  defaultValue={defaultValues?.job || ''}
                />
              </InputField>
              <InputField
                title={'한줄요약'}
                subTitle={'내가 하는 일을 간략히 요약해 주세요.'}
                sx={{ marginTop: '1rem' }}
              >
                <CustomTextInput
                  rows={1}
                  maxLength={40}
                  value={'summary'}
                  placeHolder={'ex. 포탈 Product Manager'}
                  sx={{ marginTop: '1rem' }}
                />
              </InputField>
              <InputField
                title={'상세'}
                subTitle={
                  '주요 업무, 담당 분야, 도움을 줄 수 있는 분야 등에 대해 작성해 주세요.'
                }
                sx={{ marginTop: '1rem' }}
              >
                <CustomTextInput
                  rows={6}
                  maxLength={300}
                  value={'content'}
                  placeHolder={`~을 담당하고 있습니다.\n~을 위해 ~하고 있으며,\n~가 필요할 때 저에게 연락 주세요.`}
                  sx={{ marginTop: '0.5rem' }}
                />
              </InputField>
              <StyledButtonContainer>
                <StyledCloseButton onClick={handleClose}>
                  닫기
                </StyledCloseButton>
                <StyledSubmitButton disabled={!method.formState.isValid}>
                  저장
                </StyledSubmitButton>
              </StyledButtonContainer>
            </form>
          </FormProvider>
        </DialogContent>
      </Container>
    </Dialog>
  );
};

export default JobDialog;
